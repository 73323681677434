import React from "react";

const Button = ({ link, btnStyle, buttonText, addStyle, target }) => {
  return (
    <div>
      <a
        href={link}
        className={
          btnStyle === "dark"
            ? "darkButton w-button " + addStyle
            : "lightButton w-button " + addStyle
        }
        target={target}
      >
        {buttonText}
      </a>
    </div>
  );
};

export default Button;
