import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertContext from "../context/alert/alertContext";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import Button from "../components/Button";

import "../css/form.css";
import "../css/formMedia1190.css";
import "../css/formMedia991.css";
import "../css/formMedia767.css";
import "../css/formMedia479.css";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const Contact = () => {
  const { setAlert } = useContext(AlertContext);
  const [robot, setRobot] = useState(true);

  const [contactMessage, setContactMessage] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    phone: false,
    email: false,
    message: false,
  });

  let navigate = useNavigate();

  const onChange = (e) => {
    if (e.target.id === "name" && e.target.value.length > 0) {
      setErrors({ ...errors, name: false });
    }
    if (e.target.id === "phone" && e.target.value.length > 0) {
      setErrors({ ...errors, phone: false });
    }
    if (e.target.id === "email" && e.target.value.length > 0) {
      setErrors({ ...errors, email: false });
    }
    if (e.target.id === "message" && e.target.value.length > 0) {
      setErrors({ ...errors, message: false });
    }

    setContactMessage({ ...contactMessage, [e.target.id]: e.target.value });
  };

  const validateApplication = () => {
    let name = false;
    let phone = false;
    let message = false;
    let email = false;
    let valid = true;

    if (contactMessage.name === "") {
      name = true;
      valid = false;
    }

    if (contactMessage.phone === "") {
      phone = true;
      valid = false;
    }
    if (contactMessage.message === "") {
      message = true;
      valid = false;
    }
    if (contactMessage.email === "") {
      email = true;
      valid = false;
    }

    setErrors({
      ...errors,
      name: name,
      phone: phone,
      message: message,
      email: email,
    });

    return valid;
  };

  // Google Captcha Implementation
  const captchaID = "6Lf6EMMUAAAAACdn1-MLLtPckPVVFfyMLTnP6tF2";

  const checkCaptcha = async (data) => {
    const resBody = {
      response: data,
    };
    try {
      const res = await axios.post("/api/captcha", resBody, config);
      setRobot(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const isNotRobot = (response) => {
    checkCaptcha(response);
  };

  const sendMessage = async () => {
    if (validateApplication()) {
      if (!robot) {
        try {
          await axios
            .post("/api/contact/", contactMessage, config)
            .then((res) => {
              if (res.status === 200) {
                setAlert(
                  "Your Message has been sent. We will get back to you ASAP!",
                  "Dismiss",
                  "Success"
                );
                window.scroll(0, 0);
                navigate("/");
              }
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        setAlert("Confirm your not a robot!", "Dismiss", "Error");
      }
    } else {
      window.scroll(0, 0);
      setAlert("Please fill out ALL fields", "Dismiss", "Error");
    }
  };

  return (
    <>
      <div className="contact_body_container">
        <div className="contact_card_container">
          <div className="contact_title_container">
            <h1 className="contact_title_text">Give us a Howl!</h1>
          </div>
          <div className="contact_subtitle_container">
            <div className="contact_subtitle_text">
              <p className="paragraph-2">
                Puppy Paws Rescue strives to answer all inquiries as soon as
                possible. The best way to contact us is through{" "}
                <a href="https://www.facebook.com/pg/PuppyPawsRescue">
                  Facebook Messenger
                </a>
                . However, please feel free to contact us using the below
                contact form. We will get back to you asap.
              </p>
            </div>
          </div>
          <div className="adoption_app_form_container">
            <div className="form_section_container">
              <div className="form_field_container">
                <div
                  className={
                    errors.name ? "form_error" : "form_field_container"
                  }
                >
                  {errors.name && (
                    <div className="form_error_message">Name is Manditory</div>
                  )}
                  <label htmlFor="name" className="form_label">
                    Name
                  </label>
                  <input
                    type="text"
                    onChange={onChange}
                    className="form_text_field "
                    id="name"
                  />
                </div>
              </div>
              <div className="form_field_container">
                <div
                  className={
                    errors.phone ? "form_error" : "form_field_container"
                  }
                >
                  {errors.phone && (
                    <div className="form_error_message">
                      Phone number is Manditory
                    </div>
                  )}
                  <label htmlFor="phone" className="form_label">
                    Phone
                  </label>
                  <input
                    type="text"
                    onChange={onChange}
                    className="form_text_field "
                    id="phone"
                  />
                </div>
              </div>

              <div className="form_field_container">
                <div
                  className={
                    errors.email ? "form_error" : "form_field_container"
                  }
                >
                  {errors.email && (
                    <div className="form_error_message">
                      Email Address is Manditory
                    </div>
                  )}
                  <label htmlFor="email" className="form_label">
                    Email address
                  </label>
                  <input
                    type="email"
                    onChange={onChange}
                    className="form_text_field "
                    id="email"
                  />
                </div>
              </div>

              <div className="form_field_container">
                <div
                  className={
                    errors.message ? "form_error" : "form_field_container"
                  }
                >
                  {errors.message && (
                    <div className="form_error_message">
                      Please enter a message
                    </div>
                  )}
                  <label htmlFor="message" className="form_label">
                    Message
                  </label>
                  <textarea
                    className="form_textarea"
                    onChange={onChange}
                    id="message"
                    rows="6"
                  ></textarea>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-xs-5 col-sm-3"></div>
                <div className="col-5">
                  <ReCAPTCHA sitekey={captchaID} onChange={isNotRobot} />
                </div>
              </div>
              <div className="row">
                <button
                  type="submit"
                  onClick={sendMessage}
                  className="lightButton w-button mt-2"
                >
                  SEND
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
