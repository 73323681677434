import React, { useContext } from "react";
import alertContext from "../context/alert/alertContext";

import "../css/alert.css";

const Alert = () => {
  const { showAlert, message, buttonText, closeAlert, icon } =
    useContext(alertContext);

  function dismissAlert() {
    closeAlert();
  }

  if (!showAlert) return;
  return (
    <div className="alert_body">
      <div className="alert_container">
        <div className="alert_card">
          <div className="alert_icon_container">
            {icon === "Success" && (
              <div className="dummy-positioning d-flex">
                <div className="success-icon">
                  <div className="success-icon__tip"></div>
                  <div className="success-icon__long"></div>
                </div>
              </div>
            )}
            {icon === "Error" && <div className="error_icon">ERROR</div>}
          </div>
          <div className="alert_message_container">
            <div className="alert_message">{message}</div>
          </div>
          <div className="alert_button_container">
            <div
              onClick={dismissAlert}
              className="alert_dismiss_button w-button"
            >
              {buttonText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
