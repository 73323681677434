import { Routes, Route, Outlet } from "react-router-dom";
import About from "./pages/About";
import Adopt from "./pages/Adopt";
import Contact from "./pages/Contact";
import Dogs from "./pages/Dogs";
import Donate from "./pages/Donate";
import SuccessfulCharge from "./pages/SuccessfulCharge";
import Footer from "./pages/Footer";
import Foster from "./pages/Foster";
import Header from "./pages/Header";
import Home from "./pages/Home";
import OfflineApplication from "./pages/OfflineApplicationRequest";
import Subscription from "./pages/Subscription";
import AdoptionApplication from "./pages/AdoptionApplication";
import FosterApplication from "./pages/FosterApplication";
import AlertState from "./context/alert/AlertState";
import Alert from "./components/Alert";

import "./css/normalize.css";
import "./css/webflow.css";
import "./css/puppypawsrescue.css";

function App() {
  return (
    <div className="App">
      <AlertState>
        <Alert />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="adopt" element={<Adopt />} />
            <Route
              path="adoptionApplication"
              element={<AdoptionApplication />}
            />
            <Route path="fosterApplication" element={<FosterApplication />} />
            <Route path="contact" element={<Contact />} />
            <Route path="dogs" element={<Dogs />} />
            <Route path="donate" element={<Donate />} />
            <Route path="foster" element={<Foster />} />
            <Route path="subscribe" element={<Subscription />} />
            <Route path="apptrouble" element={<OfflineApplication />} />
            <Route path="success" element={<SuccessfulCharge />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </AlertState>
    </div>
  );
}

function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;
