import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import alertContext from "../context/alert/alertContext";
import axios from "axios";

import { InitialApplicationState } from "../utils/InitialApplicationState";
import {
  TextInput,
  MultiLineTextInput,
  DropDownInput,
  CheckboxInputs,
} from "./FormComponents";

import "../css/form.css";
import "../css/formMedia1190.css";
import "../css/formMedia991.css";
import "../css/formMedia767.css";
import "../css/formMedia479.css";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const AdoptionApplicationForm = () => {
  const { setAlert } = useContext(alertContext);
  const [applicationData, setApplicationData] = useState(
    InitialApplicationState
  );
  const [errors, setErrors] = useState({
    First_Name: false,
    Last_Name: false,
    Middle_Initial: false,
    Email: false,
    State: false,
  });

  let navigate = useNavigate();

  const onChange = (e) => {
    if (e.target.id === "First_Name" && e.target.value.length > 0) {
      setErrors({ ...errors, First_Name: false });
    }
    if (e.target.id === "Middle_Initial" && e.target.value.length > 0) {
      setErrors({ ...errors, Middle_Initial: false });
    }
    if (e.target.id === "Last_Name" && e.target.value.length > 0) {
      setErrors({ ...errors, Last_Name: false });
    }
    if (e.target.id === "Email" && e.target.value.length > 0) {
      setErrors({ ...errors, Email: false });
    }
    if (e.target.id === "State") {
      if (
        e.target.value === "CA" ||
        e.target.value === "ca" ||
        e.target.value === "Ca" ||
        e.target.value === "california" ||
        e.target.value === "California"
      ) {
        setErrors({ ...errors, State: true });
      } else {
        setErrors({ ...errors, State: false });
      }
    }
    setApplicationData({ ...applicationData, [e.target.id]: e.target.value });
  };

  const handleCheckedBox = (e) => {
    if (applicationData[e.target.id] === "") {
      setApplicationData({ ...applicationData, [e.target.id]: "checked" });
    } else {
      setApplicationData({ ...applicationData, [e.target.id]: "" });
    }
  };

  const validateApplication = () => {
    let fname = false;
    let mname = false;
    let lname = false;
    let email = false;
    let valid = true;

    if (applicationData.First_Name === "") {
      fname = true;
      valid = false;
    }

    if (applicationData.Middle_Initial === "") {
      mname = true;
      valid = false;
    }
    if (applicationData.Last_Name === "") {
      lname = true;
      valid = false;
    }
    if (applicationData.Email === "") {
      email = true;
      valid = false;
    }
    if (
      applicationData.State === "CA" ||
      applicationData.State === "ca" ||
      applicationData.State === "California" ||
      applicationData.State === "california"
    ) {
      valid = false;
    }

    setErrors({
      ...errors,
      First_Name: fname,
      Middle_Initial: mname,
      Last_Name: lname,
      Email: email,
    });

    return valid;
  };

  const submitApplication = async () => {
    window.scroll(0, 0);
    if (validateApplication()) {
      try {
        const res = await axios.post(
          "/api/adoptionSubmission",
          applicationData,
          config
        );
        if (res.status === 200) {
          setAlert(
            "Application Received - Thank you for your interest in adopting",
            "Dismiss",
            "Success"
          );
          navigate("/");
        }
      } catch {
        console.log("Server Error");
      }
    } else {
      setAlert(
        "APPLICATION ERROR - Fill in all manditory items",
        "Dismiss",
        "Error"
      );
    }
  };

  return (
    <>
      <div className="adoption_app_title_container">
        <h1 className="adoption_app_title">Adoption Application</h1>
      </div>
      <div className="adoption_app_form_container">
        <form>
          <section className="interest_container form_section_container shadow">
            <div className="form_section_title">
              What dog are you interested in adopting from our rescue?
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="st_Choice"
                  label="1st Choice"
                  maxLength={100}
                  placeholder="Enter your first choice"
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="nd_Choice"
                  label="2nd Choice"
                  maxLength={100}
                  placeholder="Enter your second choice"
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <MultiLineTextInput
              field="Future_Dog2"
              label="Describe what you are looking for in a new dog"
              maxLength={2000}
              placeholder=""
              addClass=""
              rows="4"
              onChange={onChange}
            />
          </section>
          <section className="personal_container form_section_container shadow">
            <div className="form_section_title">Adopter's Information</div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="First_Name"
                  label="First Name *"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={errors.First_Name}
                  errorMessage="First Name is Manditory"
                />
              </div>
              <div className="fullSize pr-1">
                <TextInput
                  field="Middle_Initial"
                  label="Middle Name *"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={errors.Middle_Initial}
                  errorMessage="Middle Name is Manditory"
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Last_Name"
                  label="Last Name *"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={errors.Last_Name}
                  errorMessage="Last Name is Manditory"
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Phone"
                  label="Home Phone"
                  maxLength={20}
                  placeholder="XXX-XXX-XXXX"
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize pr-1">
                <TextInput
                  field="Work"
                  label="Work Phone"
                  maxLength={20}
                  placeholder="XXX-XXX-XXXX"
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Mobile"
                  label="Mobile Phone"
                  maxLength={20}
                  placeholder="XXX-XXX-XXXX"
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Email"
                  label="Email Address *"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={errors.Email}
                  errorMessage="Email Address is a Manditory Field"
                />
              </div>

              <div className="fullSize">
                <DropDownInput
                  field="Of_Age"
                  label="Are you 18 years old or older?"
                  addClass="fullSize"
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>

            <div className="halfSize">
              <TextInput
                field="CoApp_Full_Name"
                label="Co-Applicant's Name (including middle name)"
                maxLength={100}
                placeholder=""
                addClass="fullSize"
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
          </section>
          <section className="household_container form_section_container shadow">
            <div className="form_section_title">Household Information</div>
            <TextInput
              field="Street"
              label="Home Street Address"
              maxLength={250}
              placeholder=""
              addClass=""
              onChange={onChange}
              error={false}
              errorMessage=""
            />
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="City"
                  label="City"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize pr-1">
                <TextInput
                  field="State"
                  label="State"
                  maxLength={25}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={errors.State}
                  errorMessage={
                    "We do not process adoption applications in California. Our rescue is in Maryland. You may have come to our page by mistake. You may want Puppy PawZ out of Los Angelos, CA."
                  }
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Zip_Code"
                  label="Zip Code"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <TextInput
              field="County"
              label="What county do you live in?"
              maxLength={100}
              placeholder=""
              addClass=""
              onChange={onChange}
              error={false}
              errorMessage=""
            />
            <MultiLineTextInput
              field="Past_Addresses"
              label="All Addresses that you have lived at in the past 5 years"
              maxLength={250}
              placeholder=""
              addClass=""
              rows="4"
              onChange={onChange}
            />
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Number_Household"
                  label="How many people live in the household?"
                  maxLength={15}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Age_of_Children"
                  label="Ages of children living or frequently visiting house"
                  maxLength={15}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <TextInput
              field="Other_Adult_Names"
              label="Names of other adults living in the household"
              maxLength={100}
              placeholder=""
              addClass=""
              onChange={onChange}
              error={false}
              errorMessage=""
            />
            <DropDownInput
              field="Adults_Aware"
              label="Are all adults in your household aware that you are considering adopting a pet?"
              addClass=""
              onChange={onChange}
              optionsArray={["Yes", "No"]}
            />
            <TextInput
              field="Primary_Caregiver"
              label="Who will be the primary caregiver for your new dog?"
              maxLength={50}
              placeholder=""
              addClass=""
              onChange={onChange}
              error={false}
              errorMessage=""
            />
          </section>
          <section className="employment_container form_section_container shadow">
            <div className="form_section_title">Employment Information</div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Employer1"
                  label="Applicant's Employer"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Hours1"
                  label="Working Hours"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Employer2"
                  label="Co-Applicant Employer"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Hours2"
                  label="Working Hours"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
          </section>
          <section className="references_container form_section_container shadow">
            <div className="form_section_title">
              Please provide two unrelated references (neighbor, friend, or
              co-worker) that we may contact:
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Reference_1"
                  label="Name"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Reference_1_Phone"
                  label="Phone"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Reference_2"
                  label="Name"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Reference_2_Phone"
                  label="Phone"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
          </section>
          <section className="enviroment_container form_section_container shadow">
            <div className="form_section_title">Enviroment Information</div>
            <div className="checkbox_group_label">Do you live in a:</div>
            <div className="checkbox_group">
              <CheckboxInputs
                field="House"
                label="House"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Condo"
                label="Condo"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Townhouse"
                label="Townhouse"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Mobile_Home"
                label="Mobile Home"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Apartment"
                label="Apartment"
                addClass=""
                onClick={handleCheckedBox}
              />
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <div className="stacked_fields">
                  <DropDownInput
                    field="Own_Rent"
                    label="Do you own or rent your home?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Rent", "Own"]}
                  />
                </div>
              </div>
              <div className="fullSize">
                <div className="stacked_fields">
                  <DropDownInput
                    field="Pets_Permitted"
                    label="If rent, are pets permitted?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Yes", "No"]}
                  />
                </div>
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Landlord_Name"
                  label="Landlord/ Rental Agent Name"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Landlord_Phone"
                  label="Phone"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <TextInput
              field="Lived_Length_Home"
              label="How long have you lived at your present address?"
              maxLength={100}
              placeholder=""
              addClass=""
              onChange={onChange}
              error={false}
              errorMessage=""
            />
            <div className="cols">
              <div className="fullSize pr-1">
                <div className="stacked_fields">
                  <DropDownInput
                    field="Moving"
                    label="Are you planning to move in the next 6 months?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Yes", "No"]}
                  />
                </div>
              </div>
              <div className="fullSize">
                <TextInput
                  field="When_Moving"
                  label="If yes, when are you moving?"
                  maxLength={250}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="If_Move"
                  label="What will you do with your pet when/if you move?"
                  maxLength={250}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <div className="stacked_fields">
                  <DropDownInput
                    field="Kept_Where"
                    label="Will your dog be kept indoors or outdoors?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Indoor", "Outdoor"]}
                  />
                </div>
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <div className="stacked_fields">
                  <DropDownInput
                    field="Fence"
                    label="What type yard do you have?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={[
                      "Fenced Yard",
                      "Outdoor dog pen or kennel",
                      "Neither",
                    ]}
                  />
                </div>
              </div>
              <div className="fullSize">
                <TextInput
                  field="Fence_Size"
                  label="If fence/pen/kennel, please describe area size, material used, height"
                  maxLength={250}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Location_When_Home"
                  label="Where will your pet spend most of his/her time when you are home?"
                  maxLength={250}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Alone_Time"
                  label="How many hours will your pet be alone during the day?"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Location_When_Not_Home"
                  label="Where will you keep your pet when you are not home during the day?"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Location_When_Traveling"
                  label="Where will you keep your pet when you travel?"
                  maxLength={250}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Location_When_Sleeping"
                  label="Where will your pet sleep at night?"
                  maxLength={250}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Pet_Food"
                  label="What have you fed your prior/current dogs or will you feed your new dog?"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="fullSize pr-1">
              <div className="stacked_fields">
                <DropDownInput
                  field="Convictions"
                  label="To the best of your knowledge, has any member of your household ever been convicted of an animal welfare law violation such as neglect, cruelty, abandonment, etc.?"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
          </section>
          <section className="pet_history_container form_section_container shadow">
            <div className="form_section_title">Pet History</div>
            <div className="pet_input_container">
              <div className="pet_title">PET 1</div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet1_Breed"
                    label="Type/Breed"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet1_Name"
                    label="Name of Pet"
                    maxLength={100}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet1_Sex"
                    label="Sex"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["M", "F"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet1_Age"
                    label="Age"
                    maxLength={20}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields ">
                  <DropDownInput
                    field="Pet1_Fixed"
                    label="Spay/Neutered"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Yes", "No"]}
                  />
                </div>
              </div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <div className="stacked_fields ">
                    <DropDownInput
                      field="Pet1_Vac"
                      label="Heartworm, Rabies & Heartworm"
                      addClass=""
                      onChange={onChange}
                      optionsArray={["Yes", "No"]}
                    />
                  </div>
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet1_How_long"
                    label="How long in your care?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet1_Kept"
                    label="Kept inside or outside?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Inside", "Outside"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet1_WhereNow"
                    label="Where is this pet now?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
              </div>
            </div>
            <div className="pet_input_container">
              <div className="pet_title">PET 2</div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet2_Breed"
                    label="Type/Breed"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet2_Name"
                    label="Name of Pet"
                    maxLength={100}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet2_Sex"
                    label="Sex"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["M", "F"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet2_Age"
                    label="Age"
                    maxLength={20}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields ">
                  <DropDownInput
                    field="Pet2_Fixed"
                    label="Spay/Neutered"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Yes", "No"]}
                  />
                </div>
              </div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <div className="stacked_fields ">
                    <DropDownInput
                      field="Pet2_Vac"
                      label="Heartworm, Rabies & Heartworm"
                      addClass=""
                      onChange={onChange}
                      optionsArray={["Yes", "No"]}
                    />
                  </div>
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet2_How_long"
                    label="How long in your care?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet2_Kept"
                    label="Kept inside or outside?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Inside", "Outside"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet2_WhereNow"
                    label="Where is this pet now?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
              </div>
            </div>
            <div className="pet_input_container">
              <div className="pet_title">PET 3</div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet3_Breed"
                    label="Type/Breed"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet3_Name"
                    label="Name of Pet"
                    maxLength={100}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet3_Sex"
                    label="Sex"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["M", "F"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet3_Age"
                    label="Age"
                    maxLength={20}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields ">
                  <DropDownInput
                    field="Pet3_Fixed"
                    label="Spay/Neutered"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Yes", "No"]}
                  />
                </div>
              </div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <div className="stacked_fields ">
                    <DropDownInput
                      field="Pet3_Vac"
                      label="Heartworm, Rabies & Heartworm"
                      addClass=""
                      onChange={onChange}
                      optionsArray={["Yes", "No"]}
                    />
                  </div>
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet3_How_long"
                    label="How long in your care?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet3_Kept"
                    label="Kept inside or outside?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Inside", "Outside"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet3_WhereNow"
                    label="Where is this pet now?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
              </div>
            </div>
            <div className="pet_input_container">
              <div className="pet_title">PET 4</div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet4_Breed"
                    label="Type/Breed"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet4_Name"
                    label="Name of Pet"
                    maxLength={100}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet4_Sex"
                    label="Sex"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["M", "F"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet4_Age"
                    label="Age"
                    maxLength={20}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields ">
                  <DropDownInput
                    field="Pet4_Fixed"
                    label="Spay/Neutered"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Yes", "No"]}
                  />
                </div>
              </div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <div className="stacked_fields ">
                    <DropDownInput
                      field="Pet4_Vac"
                      label="Heartworm, Rabies & Heartworm"
                      addClass=""
                      onChange={onChange}
                      optionsArray={["Yes", "No"]}
                    />
                  </div>
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet4_How_long"
                    label="How long in your care?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet4_Kept"
                    label="Kept inside or outside?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Inside", "Outside"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet4_WhereNow"
                    label="Where is this pet now?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="employment_container form_section_container shadow">
            <div className="form_section_title">Veterinarian Information</div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Vet_Name"
                  label="Primary Vet Used"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Vet_Number"
                  label="Vet Number"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="fullSize pr-1">
              <TextInput
                field="Other_Vet"
                label="If you had other Veterinarians providing part of the routine care, please provide their names and phone number as well"
                maxLength={250}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="fullSize">
              <div className="stacked_fields ">
                <DropDownInput
                  field="Vet_Perm"
                  label="Do We have your permission to contact your veterinarian for a reference?"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
          </section>
          <section className="additional_information_container form_section_container shadow">
            <div className="form_section_title">Additional Information</div>
            <div className="cols">
              <div className="fullSize pr-1">
                <MultiLineTextInput
                  field="Vac_Explain"
                  label="Please note that we will not consider applicants who have not provided regular vet care to their prior pets. if there are reasons why vaccinations or heartworm preventatives were not provided you must clearly in the space provided below"
                  maxLength={2000}
                  placeholder=""
                  addClass=""
                  rows="4"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="fullSize">
              <div className="stacked_fields ">
                <DropDownInput
                  field="Policy_Acknowledged"
                  label="Please acknowledge your understanding of this policy"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
            <div className="checkbox_group_label">
              Were any of your animals:
            </div>
            <div className="checkbox_group">
              <CheckboxInputs
                field="Killed_By_Vehicle"
                label="Killed by moving Vehicle"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Given_Away"
                label="Given Away"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Lost_Stolen"
                label="Lost or stolen"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Turned_In"
                label="Turned into shelter or rescue"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="None_Apply"
                label="None Apply"
                addClass=""
                onClick={handleCheckedBox}
              />
            </div>
            <div className="cols">
              <div className="fullSize">
                <MultiLineTextInput
                  field="Outcome_Explain"
                  label="If yes, please explain"
                  maxLength={2000}
                  placeholder=""
                  addClass=""
                  rows="4"
                  onChange={onChange}
                />
              </div>
            </div>
          </section>
          <section className="additional_information_container form_section_container shadow">
            <div className="form_section_title">Preparation For New Dog</div>
            <div className="cols">
              <div className="fullSize pr-1">
                <MultiLineTextInput
                  field="Preperations"
                  label="What have you done to prepare yourself and your family for the adoption of a new pet?"
                  maxLength={2000}
                  placeholder=""
                  addClass=""
                  rows="4"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="fullSize">
              <TextInput
                field="Grooming"
                label="What level of grooming responsibility will you accept in association with your new pet?"
                maxLength={100}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="fullSize">
              <div className="stacked_fields ">
                <DropDownInput
                  field="Allergies"
                  label="Does anyone in your household have allergies to dander / hair/ saliva?"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
            <div className="fullSize">
              <div className="stacked_fields ">
                <DropDownInput
                  field="If_Allergies"
                  label="If If_Allergies exist/develop, are you willing to take actions to keep the dog?"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
            <div className="fullSize">
              <div className="stacked_fields ">
                <DropDownInput
                  field="Training"
                  label="Are you planning to attend obedience classes with your dog/puppy?"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
            <div className="fullSize">
              <TextInput
                field="Normal_Behavior"
                label="What do you consider to be normal dog/puppy behavior problems?"
                maxLength={100}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="fullSize">
              <TextInput
                field="Acclimate"
                label="How long do you feel it will take for a dog/puppy to acclimate to a new houshold?"
                maxLength={50}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="cols">
              <div className="fullSize">
                <MultiLineTextInput
                  field="Introductions"
                  label="How will you introduce your new dog to your existing pet(s)?"
                  maxLength={2000}
                  placeholder=""
                  addClass=""
                  rows="4"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="fullSize">
              <TextInput
                field="Exercise"
                label="How will you exercise the dog/puppy?"
                maxLength={100}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="fullSize">
              <TextInput
                field="Housebreak"
                label="How will you housebreak the dog/puppy?"
                maxLength={100}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="fullSize">
              <TextInput
                field="Corrections"
                label="How will you correct the dog/puppy when there is a potty accident?"
                maxLength={100}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="cols">
              <div className="fullSize">
                <MultiLineTextInput
                  field="Encourage"
                  label="How will you encourage appropriate behavior and prevent inappropriate behavior?"
                  maxLength={2000}
                  placeholder=""
                  addClass=""
                  rows="4"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="fullSize">
              <div className="stacked_fields ">
                <DropDownInput
                  field="Lifetime_Commitment"
                  label="Are you willing to make a lifetime commitment to this dog?"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
            <div className="fullSize">
              <div className="stacked_fields ">
                <DropDownInput
                  field="Will_Give_Preventatives"
                  label="Will you give heartworm tablets/flea and tick preventative every month all year round?"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
            <div className="fullSize">
              <div className="stacked_fields ">
                <DropDownInput
                  field="Medical_Care"
                  label="Will you provide all required vaccinations and medical care?"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
            <div className="fullSize">
              <TextInput
                field="Costs"
                label="How much do you think it costs, per year, to own a dog?"
                maxLength={50}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="fullSize">
              <div className="stacked_fields ">
                <DropDownInput
                  field="Expense"
                  label="Are you willing to spend the money necessary updating shots on time, spay/neutering, and emergency care for your pet?"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
            <div className="checkbox_group_label">
              Which of the following would force you to give up your pet? (check
              all that may apply)
            </div>
            <div className="checkbox_group">
              <CheckboxInputs
                field="Divorce"
                label="Divorce"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Bills"
                label="Big vet bills"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Partner_Allergies"
                label="Marry someone with allergies"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Baby"
                label="Having a baby"
                addClass=""
                onClick={handleCheckedBox}
              />

              <CheckboxInputs
                field="Barks"
                label="Barks a lot"
                addClass=""
                onClick={handleCheckedBox}
              />
            </div>
            <div className="checkbox_group">
              <CheckboxInputs
                field="Move_Out_Of_State"
                label="Move Out Of State"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Pet_Incompatible"
                label="Doesn't get along with other pet"
                addClass=""
                onClick={handleCheckedBox}
              />

              <CheckboxInputs
                field="Bladder_Issues"
                label="Loses control of bladder"
                addClass=""
                onClick={handleCheckedBox}
              />

              <CheckboxInputs
                field="Neighbors"
                label="Neighbors complain"
                addClass=""
                onClick={handleCheckedBox}
              />
            </div>
            <div className="checkbox_group">
              <CheckboxInputs
                field="Chronic_Illness"
                label="Pet develops chronic illness"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Move_No_Pets"
                label="Move where pets aren't allowed"
                addClass=""
                onClick={handleCheckedBox}
              />

              <CheckboxInputs
                field="Pet_Not_As_Expected"
                label="Pet is not the kind of pet I thought it would be"
                addClass=""
                onClick={handleCheckedBox}
              />
            </div>
            <div className="checkbox_group">
              <CheckboxInputs
                field="Un_Trainable"
                label="Un-Trainable"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Nothing_Applies"
                label="Nothing here applies"
                addClass=""
                onClick={handleCheckedBox}
              />
              <div className="other_group">
                <CheckboxInputs
                  field="Other_Checkbox"
                  label="Other"
                  addClass=""
                  onClick={handleCheckedBox}
                />
                <div className="fullSize">
                  <MultiLineTextInput
                    field="Other_Reasons"
                    label=""
                    maxLength={2000}
                    placeholder=""
                    addClass=""
                    rows="4"
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <div className="fullSize">
              <TextInput
                field="If_Die"
                label="In the event that you become very ill or die, what plans do you have for your dog?"
                maxLength={250}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>

            <div className="fullSize">
              <MultiLineTextInput
                field="Description"
                label="Any other information you feel we should know in considering your application:"
                maxLength={2000}
                placeholder=""
                addClass=""
                rows="4"
                onChange={onChange}
              />
            </div>
            <div className="fullSize">
              <div className="stacked_fields ">
                <DropDownInput
                  field="Adoption_Aware_Fee"
                  label="Are you aware that there is an adoption fee for the dog/puppy you are considering?"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
          </section>
          <section className="acknowledgement_disclaimer_container form_section_container shadow">
            <div className="form_section_title ack_center">Acknowledgement</div>
            <div className="acknowledgement_verbaige">
              By submitting this application, I acknowledge that I have answered
              all questions truthfully. Failure to provide truthful answers can
              result in the rejection of my application or the forfeiture of
              this adopted pet to Puppy Paws Rescue. If I have not received a
              telephone call within two weeks from the date of this application
              from a Puppy Paws Rescue representative, I understand that my
              application has been declined or the dog that I have requested has
              been placed with another applicant.
            </div>
            <div className="submit_button_container">
              <div
                className="submit_application_button"
                onClick={submitApplication}
              >
                Submit Application
              </div>
            </div>
          </section>
        </form>
      </div>
    </>
  );
};

export default AdoptionApplicationForm;
