export const TextInput = ({
  field,
  label,
  maxLength,
  placeholder,
  addClass,
  onChange,
  error,
  errorMessage,
}) => {
  return (
    <div className={error ? "form_error" : "form_field_container"}>
      {error && <div className="form_error_message">{errorMessage}</div>}
      <label htmlFor={field} className="form_label">
        {label}
      </label>
      <input
        type="text"
        className={"form_text_field " + addClass}
        maxLength={maxLength}
        name={field}
        placeholder={placeholder}
        id={field}
        onChange={onChange}
      />
    </div>
  );
};

export const MultiLineTextInput = ({
  field,
  label,
  maxLength,
  placeholder,
  addClass,
  onChange,
  rows,
}) => {
  return (
    <>
      <label htmlFor={field} className="form_label">
        {label}
      </label>
      <textarea
        type="text"
        className={"form_textarea" + addClass}
        maxLength={maxLength}
        name={field}
        placeholder={placeholder}
        id={field}
        onChange={onChange}
        rows={rows}
      />
    </>
  );
};

export const CheckboxInputs = ({ field, label, addClass, onClick }) => {
  return (
    <div className="w-checkbox">
      <input
        type="checkbox"
        id={field}
        name={field}
        className={"w-checkbox-input form_checkbox " + addClass}
        onClick={onClick}
      />
      <label className="checkbox_label w-form-label" htmlFor={field}>
        {label}
      </label>
    </div>
  );
};

export const DropDownInput = ({
  field,
  label,
  addClass,
  onChange,
  optionsArray,
}) => {
  return (
    <>
      <label htmlFor={field} className="form_label">
        {label}
      </label>
      <select
        className={"form_select_field " + addClass}
        name={field}
        id={field}
        onChange={onChange}
      >
        <option value="not answered">Select</option>
        {optionsArray.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </>
  );
};
