import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "../components/Button";

const Dogs = () => {
  const [dogsList, setDogsList] = useState([]);
  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [fetchingDogs, setFetchingDogs] = useState(true);

  const toggleFetchingDogs = () => {
    setFetchingDogs(!fetchingDogs);
  };

  //GET PPR ADOPTABLE PETS
  const getPets = () => {
    const config = {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    };

    axios
      .get(
        "https://api.petfinder.com/v2/animals?organization=MD411&status=adoptable&limit=100",
        config
      )
      .then(function (res) {
        setDogsList(res.data.animals);
        toggleFetchingDogs();
      })
      .catch(function (error) {
        toggleFetchingDogs();
        console.log(error);
      });
  };

  useEffect(() => {
    // CHECK FOR AUTH TOKEN & GET ONE IF NOT
    const currentDate = new Date();
    const difference = currentDate - localStorage.getItem("token_age");

    if (difference > 3600000) {
      // GetToken();
      axios
        .get("/api/getToken")
        .then(function (res) {
          localStorage.setItem("token", res.data.data);
          const date = new Date();
          localStorage.setItem("token_age", date.getTime());
          setTokenLoaded(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setTokenLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (tokenLoaded) {
      getPets();
    }

    //eslint-disable-next-line
  }, [tokenLoaded]);

  if (fetchingDogs) return;

  return (
    <div className="available_body_container">
      <div className="available_content_container">
        <div className="available_headline_section">
          <div className="available_headline">
            Currently Available For Adoption
          </div>
        </div>
        <div className="available_text_section">
          <div className="available_subtitle">
            If you are interested in meeting one of our currently available dogs
            please apply to adopt. Please keep in mind that our dogs are in
            private foster homes throughout the local area and are always by
            appointment only once the adoption application has been approved.
          </div>
        </div>
        <div className="available_cards_section">
          <div className="available_cards_gallery_container">
            {dogsList.map((dog) => (
              <div className="available_card_container" key={dog.id}>
                <div className="available_card_title_container">
                  <div className="available_card_title">{dog.name}</div>
                </div>
                <div className="available_image_container">
                  <img
                    src={dog.photos[0].medium}
                    alt="Its a dog"
                    className="available_image"
                  />
                </div>
                <div className="available_card_text_container">
                  <div className="available_text">ID # {dog.id}</div>
                  <div className="available_text">
                    Breed: {dog.breeds.primary} {dog.breeds.secondary}
                  </div>
                  <div className="available_text">Age:{dog.age}</div>
                  <div className="available_text">Gender: {dog.gender}</div>
                </div>
                <div className="available_card_button_container">
                  <Button
                    link={dog.url}
                    btnStyle={"dark"}
                    addStyle="availBtn"
                    buttonText={"MORE DETAILS"}
                    target={"_blank"}
                  />
                  <Button
                    link={"adopt"}
                    btnStyle={"light"}
                    addStyle="availBtn"
                    buttonText={"APPLY NOW"}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dogs;
