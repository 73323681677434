import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "../components/Button";

import Logo from "../media/Puppy-Paws-7.png";
import Facebook from "../media/Facebook.png";
import Instagram from "../media/instagram.png";

import "../css/header.css";

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  function toggleMobileMenu() {
    setShowMobileMenu(!showMobileMenu);
  }
  let location = useLocation();
  return (
    <header className="header">
      <div className="header_message_bar">
        <div className="header_message_bar_container">
          <div className="header_message_bar_container_text">
            <div className="header_alert_text_container">
              <div className="header_alert_text">
                Not sure how to help?{" "}
                <a href="donate" className="header_donate_link">
                  Click here to Donate!
                </a>{" "}
              </div>
            </div>
            <div className="header_social_container">
              <div className="facebook_icon_container">
                <a
                  href="https://www.facebook.com/pg/PuppyPawsRescue"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Facebook}
                    loading="lazy"
                    alt="Link to Facebook"
                    className="facebook_icon"
                  />
                </a>
              </div>
              <div className="facebook_icon_container">
                <a
                  href="https://www.instagram.com/puppypawsrescue/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Instagram}
                    loading="lazy"
                    alt="Link to Facebook"
                    className="facebook_icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header_nav_bar_container">
        <div className="header_logo_container">
          <a href="/" aria-current="page" className="navbar_link w--current">
            <img src={Logo} alt="PuppyPaws Logo" className="header_logo" />
          </a>
        </div>
        <div className="header_nav_container">
          <div className="nav_menu">
            {location.pathname !== "/" && (
              <div className="menu_link">
                <a href="/" className="menu_link_text">
                  Home
                </a>
              </div>
            )}

            <div className="menu_link">
              <a
                href="dogs"
                className={
                  location.pathname === "/dogs"
                    ? "menu_link_text nav_current"
                    : "menu_link_text"
                }
              >
                Dogs
              </a>
            </div>
            <div className="menu_link">
              <a
                href="adopt"
                className={
                  location.pathname === "/adopt"
                    ? "menu_link_text nav_current"
                    : "menu_link_text"
                }
              >
                Adopt
              </a>
            </div>
            <div className="menu_link">
              <a
                href="foster"
                className={
                  location.pathname === "/foster"
                    ? "menu_link_text nav_current"
                    : "menu_link_text"
                }
              >
                Foster
              </a>
            </div>
            <div className="menu_link">
              <a
                href="about"
                className={
                  location.pathname === "/about"
                    ? "menu_link_text nav_current"
                    : "menu_link_text"
                }
              >
                About
              </a>
            </div>
            <div className="menu_link">
              <a
                href="donate"
                className={
                  location.pathname === "/donate"
                    ? "menu_link_text nav_current"
                    : "menu_link_text"
                }
              >
                Donate
              </a>
            </div>
            <div className="menu_link">
              <a
                href="contact"
                className={
                  location.pathname === "/contact"
                    ? "menu_link_text nav_current"
                    : "menu_link_text"
                }
              >
                Contact
              </a>
            </div>
          </div>
        </div>
        <div className="header_apply_badge_container">
          <div className="apply_now_badge">
            <Button
              link={
                location.pathname === "/foster"
                  ? "fosterApplication"
                  : "adoptionApplication"
              }
              btnStyle={"dark"}
              buttonText="APPLY NOW"
            />
          </div>
        </div>
        {!showMobileMenu ? (
          <div className="nav_icon_container" onClick={toggleMobileMenu}>
            <div className="change nav_icon1"></div>
            <div className="change nav_icon2"></div>
            <div className="change nav_icon3"></div>
          </div>
        ) : (
          <div className="nav_icon_container" onClick={toggleMobileMenu}>
            <div className="change nav_x1"></div>
            <div className="change nav_x2"></div>
            <div className="change nav_x3"></div>
          </div>
        )}
      </div>
      {showMobileMenu && (
        <div className="topnav">
          <div className="topNav">
            <a href="/" aria-current="page" className="navbar_link w--current">
              Home
            </a>
            <a
              href="dogs"
              aria-current="page"
              className="navbar_link w--current"
            >
              Dogs
            </a>
            <a
              href="adopt"
              aria-current="page"
              className="navbar_link w--current"
            >
              Adopt
            </a>
            <a href="foster" className="navbar_link">
              Foster
            </a>
            <a href="donate" className="navbar_link">
              Donate
            </a>
            <a href="contact" className="navbar_link navbar_last_link">
              Contact
            </a>
            <a href="about" className="navbar_link">
              About
            </a>
            <a
              href={
                location.pathname === "/foster"
                  ? "fosterApplication"
                  : "adoptionApplication"
              }
              className="navbar_link navbar_last_link"
            >
              Apply Now
            </a>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
