import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import ApplicationDisclaimer from "../components/ApplicationDisclaimer";
import AdoptionApplicationForm from "../components/AdoptionApplicationForm";

const AdoptionApplication = () => {
  const [robot, setRobot] = useState(true);
  const [captchaError, setCaptchaError] = useState(false);
  const [page, setPage] = useState("disclaimer");

  function confirmationOfHuman(results) {
    if (!results) {
      setCaptchaError(false);
    }

    setRobot(results);
  }

  const displayApplication = () => {
    if (robot) {
      setCaptchaError(true);
      return;
    }
    window.scroll(0, 0);
    setPage("Application");
  };

  switch (page) {
    default:
    case "disclaimer":
      return (
        <div className="body">
          <ApplicationDisclaimer
            robotCheck={confirmationOfHuman}
            ReCAPTCHA={ReCAPTCHA}
            displayApplication={displayApplication}
            captchaError={captchaError}
          />
        </div>
      );
    case "Application":
      return (
        <div className="body">
          <AdoptionApplicationForm />
        </div>
      );
  }
};

export default AdoptionApplication;
