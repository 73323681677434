import React from "react";
import axios from "axios";

const ApplicationDisclaimer = ({
  robotCheck,
  ReCAPTCHA,
  displayApplication,
  captchaError,
}) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Google Captcha Implementation
  const captchaID = "6Lf6EMMUAAAAACdn1-MLLtPckPVVFfyMLTnP6tF2";

  const checkCaptcha = async (data) => {
    const resBody = {
      response: data,
    };
    try {
      const res = await axios.post("/api/captcha", resBody, config);
      robotCheck(res.data);
      //   setIsVerified(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const isNotRobot = (response) => {
    checkCaptcha(response);
  };

  return (
    <div className="application_disclaimer_container">
      <div className="application_disclaimer_title_container">
        <h1 className="application_disclaimer_title">
          ADOPTION APPLICATION DISCLAIMER
        </h1>
      </div>
      <div className="application_disclaimer_body_container">
        <div className="application_disclaimer_body_healine_text">
          Puppy Paws Rescue takes the processing of adoption applications very
          seriously. Prior to filling out the application please confirm the
          following:
        </div>
        <ol className="disclaimer_list">
          <li className="disclaimer_list_item">
            I am ready to adopt and so are all of my family members.
          </li>
          <li className="disclaimer_list_item">
            I can make arrangements to let a puppy or young dog out until
            acclimated or old enough. Puppies should not be alone longer than
            their age in months plus 1 (e.g.: 3 months old puppy+1 = 4 hours
            alone max at a time).
          </li>
          <li className="disclaimer_list_item">
            I am willing to give it 2 weeks for a dog to adjust.
          </li>
          <li className="disclaimer_list_item">
            I have spoken to my landlord or parents or other individuals that
            make decisions about allowing a dog into a home.
          </li>
          <li className="disclaimer_list_item">
            I am calling my vet if I have or had animals in the past 5 years and
            I am allowing Puppy Paws Rescue to perform a veterinary check, which
            consists of basic vaccinations - Distemper Parvo (DHPP), Rabies,
            annual heart-worm test, dog spayed or neutered and on monthly
            heart-worm prevention.
          </li>
          <li className="disclaimer_list_item">
            I am currently not in foreclosure nor in a situation to lose my home
            or residence
          </li>
          <li className="disclaimer_list_item">
            I am financially able to pay a $ 300 adoption donation, take a dog
            for a post adoption vet visit, and other cost that might incur when
            adding a dog to the household.
          </li>
        </ol>
      </div>
      <div className="application_disclaimer_captcha_container">
        {captchaError && (
          <div className="captcha_error">
            MUST PROVE YOU ARE NOT A ROBOT BELOW:
          </div>
        )}

        <div className="captcha">
          <ReCAPTCHA sitekey={captchaID} onChange={isNotRobot} />
        </div>
      </div>
      <div className="application_disclaimer_button_container">
        <a href="/" className="dont_agree_button w-button">
          DON&#x27;T AGREE
        </a>
        <div
          className="agree_button w-button"
          onClick={() => displayApplication()}
        >
          I AGREE
        </div>
      </div>
    </div>
  );
};

export default ApplicationDisclaimer;
