import React from "react";
import Button from "../components/Button";

import FosterAppPdf from "../media/Foster_App_Oct_2019.pdf";
import FosterAppWord from "../media/Foster_App_Oct_2019.doc";

const Foster = () => {
  return (
    <>
      <div className="foster_page_background">
        <div className="foster_process_container">
          <div className="foster_application_process_card">
            <div className="foster_process_title_container">
              <h3 className="foster_process_title">
                Foster Application Process
              </h3>
            </div>
            <div className="foster_process_text_container">
              <ol className="foster_process_list">
                <li className="foster_process_list_item">
                  If you would like to become a foster, submit our online foster
                  application by clicking the &quot;Online Application&quot;
                  link below or fill out one of the downloadable versions of our
                  application and email it to{" "}
                  <a href="mailto:application@PuppyPawsRescue.org">
                    application@PuppyPawsRescue.org
                  </a>
                  .
                </li>
                <li className="foster_process_list_item">
                  <div>
                    Our team of volunteers will review your application. This
                    review includes:
                  </div>
                  <ol className="adoption_process_sub_list">
                    <li className="foster_process_sub_list_item">
                      Review of vaccinations/spay or neuter/heartworm prevention
                      of existing or past pets by speaking with your
                      veterinarian. Prior to filling out the application,{" "}
                      <strong>
                        please call your vet and give your permission for us to
                        speak with them.
                      </strong>
                    </li>
                    <li className="foster_process_list_item">
                      Calling to speak with provided references.
                    </li>
                    <li className="foster_process_list_item">
                      Phone interview to discuss your application and clarify
                      any questions.
                    </li>
                    <li className="foster_process_sub_list_item">
                      Virtual or in-person home visits to ascertain the current
                      household environment. This includes the living area for
                      the pet including the outside space.
                    </li>
                    <li className="foster_process_sub_list_item">
                      Once approved, a coordinator will be in contact with you
                      to discuss your availability and arranging your first
                      foster pet.
                    </li>
                  </ol>
                </li>
              </ol>
              <div className="foster_application_button_card">
                <div className="foster_apply_button_container">
                  <Button
                    link={"fosterApplication"}
                    btnStyle={"light"}
                    buttonText={"Apply Online"}
                    addStyle={"fosterBTN"}
                  />
                </div>
              </div>
            </div>
            <div className="foster_application_button_card">
              <div className="foster_download_title_container">
                <h3 className="foster_process_title">
                  Downloadable Applications
                </h3>
              </div>
              <div className="foster_downoad_button_container">
                <Button
                  link={FosterAppWord}
                  btnStyle={"light"}
                  buttonText={"MS WORD"}
                  addStyle={"fosterDownloadBTN"}
                />
                <Button
                  link={FosterAppPdf}
                  btnStyle={"light"}
                  buttonText={"PDF"}
                  addStyle={"fosterDownloadBTN"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Foster;
