import React from "react";
import Button from "../components/Button";

import "../css/puppypawsrescue.css";

const Adopt = () => {
  return (
    <>
      <div className="adoption_page_background">
        <div className="adoption_page_container">
          <div className="adoption_upper_container">
            <div className="adoption_fee_container">
              <div className="adoption_fee_card">
                <div className="adoption_fee_schedule_container">
                  <div className="adoption_fee_header_container">
                    <h4 className="adoption_fee_header_text">Adoption Fees</h4>
                  </div>
                  <div className="adoption_fee_schedule">
                    <div className="adoption_fee_item">
                      Dogs (Over 2 years old)
                    </div>
                    <div className="adoption_fee_price">$250</div>
                    <div className="adoption_fee_item">
                      Puppies (Below 2 years old)
                    </div>
                    <div className="adoption_fee_price">$300</div>
                  </div>
                </div>
                <div className="adoption_apply_button_container">
                  <Button
                    link={"adoptionApplication"}
                    btnStyle={"light"}
                    buttonText={"Apply Now "}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="adoption_process_container">
            <div className="adoption_process_card">
              <div className="adoption_process_title_container">
                <h3 className="adoption_process_title">Adoption Process</h3>
              </div>
              <div className="adoption_process_text_container">
                <ol className="adoption_process_list">
                  <li className="adoption_process_list_item">
                    To review our currently adoptable dogs,{" "}
                    <a href="/dogs" className="adoption_dog_available_link">
                      click here
                    </a>
                    .
                  </li>
                  <li className="adoption_process_list_item">
                    If you would like to meet one of our pets or be
                    pre-qualified for adoption for one of our future dogs,
                    submit our online adoption application
                  </li>
                  <li className="adoption_process_list_item">
                    <div>
                      Our team of volunteers will review your application. This
                      review includes:
                    </div>
                    <ol className="adoption_process_sub_list">
                      <li className="adoption_process_sub_list_item">
                        Review of vaccinations/spay or neuter/heartworm
                        prevention of existing or past pets by speaking with
                        your veterinarian. Prior to filling out the application,{" "}
                        <strong>
                          please call your vet and give your permission for us
                          to speak with them.
                        </strong>
                      </li>
                      <li className="adoption_process_sub_list_item">
                        Calling to speak with provided references.
                      </li>
                      <li className="adoption_process_sub_list_item">
                        Phone interview to discuss your application and clarify
                        any questions.
                      </li>
                      <li className="adoption_process_sub_list_item">
                        Virtual or in-person home visits to ascertain the
                        current household environment. This includes the living
                        area for the pet including the outside space.
                      </li>
                      <li className="adoption_process_sub_list_item">
                        Once approved, if you have interest in one of our
                        available dogs, you can request a meet and greet to be
                        setup.
                      </li>
                    </ol>
                  </li>
                  <li className="adoption_process_list_item">
                    Please understand that some of our dogs have multiple
                    potential adopters interested in them. We make every effort
                    to place our dogs in situations that are the best fits for
                    their needs and personality.
                    <br />
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="adoption_lower_container">
            <div className="adoption_application_issues_container">
              <div className="adoption_application_issues_title_container">
                <h3 className="adoption_application_issues_title">
                  Having trouble applying?
                </h3>
              </div>
              <div className="adoption_application_issues_text_container">
                <div className="adoption_application_issue_text">
                  If you are having trouble filling out our online application{" "}
                  <a href="/apptrouble">click here</a> to have an application
                  emailed to you.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Adopt;
