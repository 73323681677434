import React from "react";

import "../css/footer.css";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer_container">
          <div className="footer_left_container">
            <h3 className="footer_company_name">PuppyPaws Rescue</h3>
            <p className="paragraph">
              PO Box 45
              <br /> Huntingtown, MD 20639
              <br /> information@puppypawsrescue.org
              <br /> (202) 630-5874
            </p>
          </div>
          <div className="footer_right_container">
            <div className="footer_text">
              Our adoptable pets are fostered in our foster family&#x27;s homes
              around the local area. Fill out an Application to schedule a meet
              and greet.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
