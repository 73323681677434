import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import alertContext from "../context/alert/alertContext";
import axios from "axios";

import { InitialApplicationState } from "../utils/InitialApplicationState";
import {
  TextInput,
  MultiLineTextInput,
  DropDownInput,
  CheckboxInputs,
} from "./FormComponents";

import "../css/form.css";
import "../css/formMedia1190.css";
import "../css/formMedia991.css";
import "../css/formMedia767.css";
import "../css/formMedia479.css";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const FosterApplicationForm = () => {
  const { setAlert } = useContext(alertContext);
  const [applicationData, setApplicationData] = useState(
    InitialApplicationState
  );
  const [errors, setErrors] = useState({
    First_Name: false,
    Last_Name: false,
    Middle_Initial: false,
    Email: false,
  });

  let navigate = useNavigate();

  useEffect(() => {
    setApplicationData({ ...applicationData, Application_Type: "Foster" });

    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    if (e.target.id === "First_Name" && e.target.value.length > 0) {
      setErrors({ ...errors, First_Name: false });
    }
    if (e.target.id === "Middle_Initial" && e.target.value.length > 0) {
      setErrors({ ...errors, Middle_Initial: false });
    }
    if (e.target.id === "Last_Name" && e.target.value.length > 0) {
      setErrors({ ...errors, Last_Name: false });
    }
    if (e.target.id === "Email" && e.target.value.length > 0) {
      setErrors({ ...errors, Email: false });
    }
    setApplicationData({ ...applicationData, [e.target.id]: e.target.value });
  };

  const handleCheckedBox = (e) => {
    if (applicationData[e.target.id] === "") {
      setApplicationData({ ...applicationData, [e.target.id]: "checked" });
    } else {
      setApplicationData({ ...applicationData, [e.target.id]: "" });
    }
  };

  const validateApplication = () => {
    let fname = false;
    let mname = false;
    let lname = false;
    let email = false;
    let valid = true;

    if (applicationData.First_Name === "") {
      fname = true;
      valid = false;
    }

    if (applicationData.Middle_Initial === "") {
      mname = true;
      valid = false;
    }
    if (applicationData.Last_Name === "") {
      lname = true;
      valid = false;
    }
    if (applicationData.Email === "") {
      email = true;
      valid = false;
    }

    setErrors({
      ...errors,
      First_Name: fname,
      Middle_Initial: mname,
      Last_Name: lname,
      Email: email,
    });

    return valid;
  };

  const submitApplication = async () => {
    window.scroll(0, 0);
    if (validateApplication()) {
      try {
        const res = await axios.post(
          "/api/fosterSubmission",
          applicationData,
          config
        );
        if (res.status === 200) {
          setAlert(
            "Application Received - Thank you for your interest in fostering",
            "Dismiss",
            "Success"
          );
          navigate("/");
        }
      } catch {
        console.log("Server Error");
      }
    } else {
      setAlert(
        "APPLICATION ERROR - Fill in all manditory items",
        "Dismiss",
        "Error"
      );
    }
  };

  return (
    <div>
      <div className="adoption_app_title_container">
        <h1 className="adoption_app_title">Foster Application</h1>
      </div>
      <div className="adoption_app_form_container">
        <form>
          <section className="interest_container form_section_container shadow">
            <div className="form_section_title">
              I AM INTERESTED IN FOSTERING:{" "}
              <span className="form_section_title_fine_print">
                (check all that apply)
              </span>
            </div>
            <div className="cols">
              <div className="checkbox_group">
                <CheckboxInputs
                  field="Interest_Adult"
                  label="Adult"
                  addClass=""
                  onClick={handleCheckedBox}
                />
                <CheckboxInputs
                  field="Interest_Puppy"
                  label="Puppy"
                  addClass=""
                  onClick={handleCheckedBox}
                />
                <CheckboxInputs
                  field="Interest_Mom"
                  label="Mom with Puppies"
                  addClass=""
                  onClick={handleCheckedBox}
                />
                <CheckboxInputs
                  field="Interest_Litter"
                  label="Litter of puppies"
                  addClass=""
                  onClick={handleCheckedBox}
                />
                <CheckboxInputs
                  field="Interest_Set2Pups"
                  label="Set of two pups"
                  addClass=""
                  onClick={handleCheckedBox}
                />
                <CheckboxInputs
                  field="Interest_NoPref"
                  label="No Preference"
                  addClass=""
                  onClick={handleCheckedBox}
                />
              </div>
            </div>
            <div className="form_section_title">Gender Preference</div>
            <div className="cols">
              <div className="small_checkbox_group">
                <CheckboxInputs
                  field="Interest_Male"
                  label="Male"
                  addClass=""
                  onClick={handleCheckedBox}
                />
                <CheckboxInputs
                  field="Interest_Female"
                  label="Female"
                  addClass=""
                  onClick={handleCheckedBox}
                />
                <CheckboxInputs
                  field="Interest_Either"
                  label="Either"
                  addClass=""
                  onClick={handleCheckedBox}
                />
              </div>
            </div>
            <div className="form_section_title">Size Preference</div>
            <div className="cols">
              <div className="small_checkbox_group">
                <CheckboxInputs
                  field="Interest_Large"
                  label="Large"
                  addClass=""
                  onClick={handleCheckedBox}
                />
                <CheckboxInputs
                  field="Interest_Medium"
                  label="Medium"
                  addClass=""
                  onClick={handleCheckedBox}
                />
                <CheckboxInputs
                  field="Interest_Small"
                  label="Small"
                  addClass=""
                  onClick={handleCheckedBox}
                />
                <CheckboxInputs
                  field="Interest_SzNoPref"
                  label="No Preference"
                  addClass=""
                  onClick={handleCheckedBox}
                />
              </div>
            </div>
            <MultiLineTextInput
              field="Interest_AddNeeds"
              label="Please describe any additional preferences or needs:"
              maxLength={2000}
              placeholder=""
              addClass=""
              rows="4"
              onChange={onChange}
            />
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Foster_Length"
                  label="How long can you foster?"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
          </section>
          <section className="personal_container form_section_container shadow">
            <div className="form_section_title">Foster's Information</div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="First_Name"
                  label="First Name *"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={errors.First_Name}
                  errorMessage="First Name is Manditory"
                />
              </div>
              <div className="fullSize pr-1">
                <TextInput
                  field="Middle_Initial"
                  label="Middle Name *"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={errors.Middle_Initial}
                  errorMessage="Middle Name is Manditory"
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Last_Name"
                  label="Last Name *"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={errors.Last_Name}
                  errorMessage="Last Name is Manditory"
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Phone"
                  label="Home Phone"
                  maxLength={20}
                  placeholder="XXX-XXX-XXXX"
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize pr-1">
                <TextInput
                  field="Work"
                  label="Work Phone"
                  maxLength={20}
                  placeholder="XXX-XXX-XXXX"
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Mobile"
                  label="Mobile Phone"
                  maxLength={20}
                  placeholder="XXX-XXX-XXXX"
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Email"
                  label="Email Address *"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={errors.Email}
                  errorMessage="Email Address is a Manditory Field"
                />
              </div>

              <div className="fullSize">
                <DropDownInput
                  field="Of_Age"
                  label="Are you 18 years old or older?"
                  addClass="fullSize"
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>

            <div className="halfSize">
              <TextInput
                field="CoApp_Full_Name"
                label="Co-Applicant's Name (including middle name)"
                maxLength={100}
                placeholder=""
                addClass="fullSize"
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
          </section>
          <section className="household_container form_section_container shadow">
            <div className="form_section_title">Household Information</div>
            <TextInput
              field="Street"
              label="Home Street Address"
              maxLength={250}
              placeholder=""
              addClass=""
              onChange={onChange}
              error={false}
              errorMessage=""
            />
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="City"
                  label="City"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize pr-1">
                <TextInput
                  field="State"
                  label="State"
                  maxLength={25}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Zip_Code"
                  label="Zip Code"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <TextInput
              field="County"
              label="What county do you live in?"
              maxLength={100}
              placeholder=""
              addClass=""
              onChange={onChange}
              error={false}
              errorMessage=""
            />
            <MultiLineTextInput
              field="Past_Addresses"
              label="All Addresses that you have lived at in the past 5 years"
              maxLength={250}
              placeholder=""
              addClass=""
              rows="4"
              onChange={onChange}
            />
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Number_Household"
                  label="How many people live in the household?"
                  maxLength={15}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Age_of_Children"
                  label="Ages of children living or frequently visiting house"
                  maxLength={15}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <TextInput
              field="Other_Adult_Names"
              label="Names of other adults living in the household"
              maxLength={100}
              placeholder=""
              addClass=""
              onChange={onChange}
              error={false}
              errorMessage=""
            />
            <DropDownInput
              field="Adults_Aware"
              label="Are all adults in your household aware that you are considering fostering a pet?"
              addClass=""
              onChange={onChange}
              optionsArray={["Yes", "No"]}
            />
            <TextInput
              field="Primary_Caregiver"
              label="Who will be the primary caregiver for your foster dog?"
              maxLength={50}
              placeholder=""
              addClass=""
              onChange={onChange}
              error={false}
              errorMessage=""
            />
          </section>
          <section className="employment_container form_section_container shadow">
            <div className="form_section_title">Employment Information</div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Employer1"
                  label="Applicant's Employer"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Hours1"
                  label="Working Hours"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Employer2"
                  label="Co-Applicant Employer"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Hours2"
                  label="Working Hours"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
          </section>
          <section className="references_container form_section_container shadow">
            <div className="form_section_title">
              Please provide two unrelated references (neighbor, friend, or
              co-worker) that we may contact:
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Reference_1"
                  label="Name"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Reference_1_Phone"
                  label="Phone"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Reference_2"
                  label="Name"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Reference_2_Phone"
                  label="Phone"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
          </section>
          <section className="enviroment_container form_section_container shadow">
            <div className="form_section_title">Enviroment Information</div>
            <div className="checkbox_group_label">Do you live in a:</div>
            <div className="checkbox_group">
              <CheckboxInputs
                field="House"
                label="House"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Condo"
                label="Condo"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Townhouse"
                label="Townhouse"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Mobile_Home"
                label="Mobile Home"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Apartment"
                label="Apartment"
                addClass=""
                onClick={handleCheckedBox}
              />
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <div className="stacked_fields">
                  <DropDownInput
                    field="Own_Rent"
                    label="Do you own or rent your home?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Rent", "Own"]}
                  />
                </div>
              </div>
              <div className="fullSize">
                <div className="stacked_fields">
                  <DropDownInput
                    field="Pets_Permitted"
                    label="If rent, are pets permitted?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Yes", "No"]}
                  />
                </div>
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Landlord_Name"
                  label="Landlord/ Rental Agent Name"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Landlord_Phone"
                  label="Phone"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <TextInput
              field="Lived_Length_Home"
              label="How long have you lived at your present address?"
              maxLength={100}
              placeholder=""
              addClass=""
              onChange={onChange}
              error={false}
              errorMessage=""
            />
            <div className="cols">
              <div className="fullSize pr-1">
                <div className="stacked_fields">
                  <DropDownInput
                    field="Moving"
                    label="Are you planning to move in the next 6 months?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Yes", "No"]}
                  />
                </div>
              </div>
              <div className="fullSize">
                <TextInput
                  field="When_Moving"
                  label="If yes, when are you moving?"
                  maxLength={250}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="If_Move"
                  label="What will you do with your foster pet when/if you move?"
                  maxLength={250}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <div className="stacked_fields">
                  <DropDownInput
                    field="Kept_Where"
                    label="Will your dog be kept indoors or outdoors?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Indoor", "Outdoor"]}
                  />
                </div>
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <div className="stacked_fields">
                  <DropDownInput
                    field="Fence"
                    label="What type yard do you have?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={[
                      "Fenced Yard",
                      "Outdoor dog pen or kennel",
                      "Neither",
                    ]}
                  />
                </div>
              </div>
              <div className="fullSize">
                <TextInput
                  field="Fence_Size"
                  label="If fence/pen/kennel, please describe area size, material used, height"
                  maxLength={250}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Location_When_Home"
                  label="Where will your foster spend most of his/her time when you are home?"
                  maxLength={250}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Alone_Time"
                  label="How many hours will your foster be alone during the day?"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Location_When_Not_Home"
                  label="Where will you keep your foster when you are not home during the day?"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Location_When_Traveling"
                  label="Where will you keep your foster when you travel?"
                  maxLength={250}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Location_When_Sleeping"
                  label="Where will your foster sleep at night?"
                  maxLength={250}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Pet_Food"
                  label="What have you fed your prior/current dogs or will you feed your new dog?"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="fullSize pr-1">
              <div className="stacked_fields">
                <DropDownInput
                  field="Convictions"
                  label="To the best of your knowledge, has any member of your household ever been convicted of an animal welfare law violation such as neglect, cruelty, abandonment, etc.?"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
          </section>
          <section className="pet_history_container form_section_container shadow">
            <div className="form_section_title">Pet History</div>
            <div className="pet_input_container">
              <div className="pet_title">PET 1</div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet1_Breed"
                    label="Type/Breed"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet1_Name"
                    label="Name of Pet"
                    maxLength={100}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet1_Sex"
                    label="Sex"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["M", "F"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet1_Age"
                    label="Age"
                    maxLength={20}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields ">
                  <DropDownInput
                    field="Pet1_Fixed"
                    label="Spay/Neutered"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Yes", "No"]}
                  />
                </div>
              </div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <div className="stacked_fields ">
                    <DropDownInput
                      field="Pet1_Vac"
                      label="Heartworm, Rabies & Heartworm"
                      addClass=""
                      onChange={onChange}
                      optionsArray={["Yes", "No"]}
                    />
                  </div>
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet1_How_long"
                    label="How long in your care?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet1_Kept"
                    label="Kept inside or outside?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Inside", "Outside"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet1_WhereNow"
                    label="Where is this pet now?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
              </div>
            </div>
            <div className="pet_input_container">
              <div className="pet_title">PET 2</div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet2_Breed"
                    label="Type/Breed"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet2_Name"
                    label="Name of Pet"
                    maxLength={100}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet2_Sex"
                    label="Sex"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["M", "F"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet2_Age"
                    label="Age"
                    maxLength={20}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields ">
                  <DropDownInput
                    field="Pet2_Fixed"
                    label="Spay/Neutered"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Yes", "No"]}
                  />
                </div>
              </div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <div className="stacked_fields ">
                    <DropDownInput
                      field="Pet2_Vac"
                      label="Heartworm, Rabies & Heartworm"
                      addClass=""
                      onChange={onChange}
                      optionsArray={["Yes", "No"]}
                    />
                  </div>
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet2_How_long"
                    label="How long in your care?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet2_Kept"
                    label="Kept inside or outside?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Inside", "Outside"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet2_WhereNow"
                    label="Where is this pet now?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
              </div>
            </div>
            <div className="pet_input_container">
              <div className="pet_title">PET 3</div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet3_Breed"
                    label="Type/Breed"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet3_Name"
                    label="Name of Pet"
                    maxLength={100}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet3_Sex"
                    label="Sex"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["M", "F"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet3_Age"
                    label="Age"
                    maxLength={20}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields ">
                  <DropDownInput
                    field="Pet3_Fixed"
                    label="Spay/Neutered"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Yes", "No"]}
                  />
                </div>
              </div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <div className="stacked_fields ">
                    <DropDownInput
                      field="Pet3_Vac"
                      label="Heartworm, Rabies & Heartworm"
                      addClass=""
                      onChange={onChange}
                      optionsArray={["Yes", "No"]}
                    />
                  </div>
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet3_How_long"
                    label="How long in your care?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet3_Kept"
                    label="Kept inside or outside?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Inside", "Outside"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet3_WhereNow"
                    label="Where is this pet now?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
              </div>
            </div>
            <div className="pet_input_container">
              <div className="pet_title">PET 4</div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet4_Breed"
                    label="Type/Breed"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet4_Name"
                    label="Name of Pet"
                    maxLength={100}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet4_Sex"
                    label="Sex"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["M", "F"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet4_Age"
                    label="Age"
                    maxLength={20}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields ">
                  <DropDownInput
                    field="Pet4_Fixed"
                    label="Spay/Neutered"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Yes", "No"]}
                  />
                </div>
              </div>
              <div className="cols">
                <div className="fullSize pr-1">
                  <div className="stacked_fields ">
                    <DropDownInput
                      field="Pet4_Vac"
                      label="Heartworm, Rabies & Heartworm"
                      addClass=""
                      onChange={onChange}
                      optionsArray={["Yes", "No"]}
                    />
                  </div>
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet4_How_long"
                    label="How long in your care?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
                <div className="stacked_fields fullSize pr-1">
                  <DropDownInput
                    field="Pet4_Kept"
                    label="Kept inside or outside?"
                    addClass=""
                    onChange={onChange}
                    optionsArray={["Inside", "Outside"]}
                  />
                </div>
                <div className="fullSize pr-1">
                  <TextInput
                    field="Pet4_WhereNow"
                    label="Where is this pet now?"
                    maxLength={50}
                    placeholder=""
                    addClass=""
                    onChange={onChange}
                    error={false}
                    errorMessage=""
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="employment_container form_section_container shadow">
            <div className="form_section_title">Veterinarian Information</div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Vet_Name"
                  label="Primary Vet Used"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Vet_Number"
                  label="Vet Number"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="fullSize pr-1">
              <TextInput
                field="Other_Vet"
                label="If you had other Veterinarians providing part of the routine care, please provide their names and phone number as well"
                maxLength={250}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="fullSize">
              <div className="stacked_fields ">
                <DropDownInput
                  field="Vet_Perm"
                  label="Do We have your permission to contact your veterinarian for a reference?"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
          </section>
          <section className="additional_information_container form_section_container shadow">
            <div className="form_section_title">Additional Information</div>
            <div className="cols">
              <div className="fullSize pr-1">
                <MultiLineTextInput
                  field="Vac_Explain"
                  label="Please note that we will not consider applicants who have not provided regular vet care to their prior pets. if there are reasons why vaccinations or heartworm preventatives were not provided you must clearly in the space provided below"
                  maxLength={2000}
                  placeholder=""
                  addClass=""
                  rows="4"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="fullSize">
              <div className="stacked_fields ">
                <DropDownInput
                  field="Policy_Acknowledged"
                  label="Please acknowledge your understanding of this policy"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
            <div className="checkbox_group_label">
              Were any of your animals:
            </div>
            <div className="checkbox_group">
              <CheckboxInputs
                field="Killed_By_Vehicle"
                label="Killed by moving Vehicle"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Given_Away"
                label="Given Away"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Lost_Stolen"
                label="Lost or stolen"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="Turned_In"
                label="Turned into shelter or rescue"
                addClass=""
                onClick={handleCheckedBox}
              />
              <CheckboxInputs
                field="None_Apply"
                label="None Apply"
                addClass=""
                onClick={handleCheckedBox}
              />
            </div>
            <div className="cols">
              <div className="fullSize">
                <MultiLineTextInput
                  field="Outcome_Explain"
                  label="If yes, please explain"
                  maxLength={2000}
                  placeholder=""
                  addClass=""
                  rows="4"
                  onChange={onChange}
                />
              </div>
            </div>
          </section>
          <section className="additional_information_container form_section_container shadow">
            <div className="form_section_title">Preparation For New Dog</div>
            <div className="cols">
              <div className="fullSize pr-1">
                <MultiLineTextInput
                  field="Preperations"
                  label="What have you done to prepare yourself and your family for fostering of a dog?"
                  maxLength={2000}
                  placeholder=""
                  addClass=""
                  rows="4"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="fullSize">
              <div className="stacked_fields ">
                <DropDownInput
                  field="Allergies"
                  label="Does anyone in your household have allergies to dander / hair/ saliva?"
                  addClass=""
                  onChange={onChange}
                  optionsArray={["Yes", "No"]}
                />
              </div>
            </div>
            <div className="fullSize">
              <TextInput
                field="Normal_Behavior"
                label="What do you consider to be normal dog/puppy behavior problems?"
                maxLength={100}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="fullSize">
              <TextInput
                field="Acclimate"
                label="How long do you feel it will take for a foster to acclimate to a new houshold?"
                maxLength={50}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="cols">
              <div className="fullSize">
                <MultiLineTextInput
                  field="Introductions"
                  label="How will you introduce your foster to your existing pet(s)?"
                  maxLength={2000}
                  placeholder=""
                  addClass=""
                  rows="4"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="fullSize">
              <TextInput
                field="Exercise"
                label="How will you exercise the foster?"
                maxLength={100}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="fullSize">
              <TextInput
                field="Housebreak"
                label="How will you housebreak the foster?"
                maxLength={100}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="fullSize">
              <TextInput
                field="Corrections"
                label="How will you correct the foster when there is a potty accident?"
                maxLength={100}
                placeholder=""
                addClass=""
                onChange={onChange}
                error={false}
                errorMessage=""
              />
            </div>
            <div className="cols">
              <div className="fullSize">
                <MultiLineTextInput
                  field="Encourage"
                  label="How will you encourage appropriate behavior and prevent inappropriate behavior?"
                  maxLength={2000}
                  placeholder=""
                  addClass=""
                  rows="4"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="fullSize">
              <MultiLineTextInput
                field="Description"
                label="Any other information you feel we should know in considering your application:"
                maxLength={2000}
                placeholder=""
                addClass=""
                rows="4"
                onChange={onChange}
              />
            </div>
          </section>
          <section className="acknowledgement_disclaimer_container form_section_container shadow">
            <div className="form_section_title ack_center">Acknowledgement</div>
            <div className="acknowledgement_verbaige">
              By submitting this application, I acknowledge that I have answered
              all questions truthfully.
            </div>
            <div className="submit_button_container">
              <div
                className="submit_application_button"
                onClick={submitApplication}
              >
                Submit Application
              </div>
            </div>
          </section>
        </form>
      </div>
    </div>
  );
};

export default FosterApplicationForm;
