import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import alertContext from "../context/alert/alertContext";
import axios from "axios";

import { TextInput, MultiLineTextInput } from "../components/FormComponents";

import "../css/form.css";
import "../css/formMedia1190.css";
import "../css/formMedia991.css";
import "../css/formMedia767.css";
import "../css/formMedia479.css";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const initialApplicationState = {
  First_Name: "",
  Last_Name: "",
  Address: "",
  City: "",
  State: "",
  Zip_Code: "",
  Phone: "",
  Email: "",
  OS_Type: "",
  Browser: "",
  Device_Browser: "",
  Device_Type: "",
  Direct_Link: "",
  Details: "",
  Owner: { id: "35194048" },
  Owner_Id: "1045",
  Application_Type: "Adoption",
  Application_Status: "Email App Requested",
  Application_Current_Status: "closed",
  Lead_Status: "Email App Requested",
  Lead_Source: "Email Applications",
};

const OfflineApplicationRequest = () => {
  const { setAlert } = useContext(alertContext);
  const [applicationData, setApplicationData] = useState(
    initialApplicationState
  );
  const [errors, setErrors] = useState({
    First_Name: false,
    Last_Name: false,
    Middle_Initial: false,
    Email: false,
  });

  let navigate = useNavigate();

  const onChange = (e) => {
    if (e.target.id === "First_Name" && e.target.value.length > 0) {
      setErrors({ ...errors, First_Name: false });
    }
    if (e.target.id === "Last_Name" && e.target.value.length > 0) {
      setErrors({ ...errors, Last_Name: false });
    }
    if (e.target.id === "Email" && e.target.value.length > 0) {
      setErrors({ ...errors, Email: false });
    }
    setApplicationData({ ...applicationData, [e.target.id]: e.target.value });
  };

  const validateApplication = () => {
    let fname = false;
    let mname = false;
    let lname = false;
    let email = false;
    let valid = true;

    if (applicationData.First_Name === "") {
      fname = true;
      valid = false;
    }

    if (applicationData.Middle_Initial === "") {
      mname = true;
      valid = false;
    }
    if (applicationData.Last_Name === "") {
      lname = true;
      valid = false;
    }
    if (applicationData.Email === "") {
      email = true;
      valid = false;
    }

    setErrors({
      ...errors,
      First_Name: fname,
      Middle_Initial: mname,
      Last_Name: lname,
      Email: email,
    });

    return valid;
  };

  const submitApplication = async () => {
    window.scroll(0, 0);
    if (validateApplication()) {
      try {
        const res = await axios.post(
          "/api/offlineApplication",
          applicationData,
          config
        );
        if (res.status === 200) {
          setAlert(
            "Request Received - An adoption application has been emailed to you",
            "Dismiss",
            "Success"
          );
          navigate("/");
        }
      } catch {
        console.log("Server Error");
      }
    } else {
      setAlert(
        "APPLICATION ERROR - Fill in all manditory items",
        "Dismiss",
        "Error"
      );
    }
  };

  return (
    <div className="body">
      <div className="adoption_app_title_container">
        <h1 className="adoption_app_title">
          OFFLINE ADOPTION APPLICATION REQUEST
        </h1>
        <p className="offline_application_instructions">
          We are sorry you are having trouble with our online application. We
          would still like to get your adoption application so please fill out
          the below basic form and we will email you an application that you can
          fill out and email back to us.
        </p>
      </div>
      <div className="adoption_app_form_container">
        <form>
          <section className="interest_container form_section_container shadow">
            <div className="offline_form_section_title">
              Request Application Via Email
            </div>
            <p className="offline_application_instructions">
              (fill out the below form)
            </p>
          </section>
          <section className="personal_container form_section_container shadow">
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="First_Name"
                  label="First Name *"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={errors.First_Name}
                  errorMessage="First Name is Manditory"
                />
              </div>

              <div className="fullSize">
                <TextInput
                  field="Last_Name"
                  label="Last Name *"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={errors.Last_Name}
                  errorMessage="Last Name is Manditory"
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize">
                <TextInput
                  field="Phone"
                  label="Phone"
                  maxLength={20}
                  placeholder="XXX-XXX-XXXX"
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Email"
                  label="Email Address *"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={errors.Email}
                  errorMessage="Email Address is a Manditory Field"
                />
              </div>
            </div>
          </section>
          <section className="household_container form_section_container shadow">
            <TextInput
              field="Street"
              label="Home Street Address"
              maxLength={250}
              placeholder=""
              addClass=""
              onChange={onChange}
              error={false}
              errorMessage=""
            />
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="City"
                  label="City"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize pr-1">
                <TextInput
                  field="State"
                  label="State"
                  maxLength={25}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
              <div className="fullSize">
                <TextInput
                  field="Zip_Code"
                  label="Zip Code"
                  maxLength={20}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
          </section>
          <section className="employment_container form_section_container shadow">
            <div className="form_section_title">PLEASE HELP US</div>
            <p className="offline_tech_problems_request_text">
              If you had technical issues filling out our online application,
              please answer the below questions. This will assist us in
              improving our online application process.
            </p>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Device_Type"
                  label="What device did you use to fill out the application? (Example: Windows PC, MAC, iPhone, iPad, Android)"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Device_Browser"
                  label="What browser did you use to fill out the application? (Example: Chrome, Safari, Edge, Internet Explorer, Firefox)"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>
            <div className="cols">
              <div className="fullSize pr-1">
                <TextInput
                  field="Direct_Link"
                  label="Did you go to the site from Facebook Link or go directly to our website inside your browser?"
                  maxLength={100}
                  placeholder=""
                  addClass=""
                  onChange={onChange}
                  error={false}
                  errorMessage=""
                />
              </div>
            </div>

            <div className="cols">
              <div className="fullSize pr-1">
                <MultiLineTextInput
                  field="Details"
                  label="Please provide any other details you can give. (example: what happen with the site? What was the last question that you answered before it failed? Did you try another device and got the same results?, Would you be interested in helping us by speaking to our web developer?)"
                  maxLength={2000}
                  placeholder=""
                  addClass=""
                  rows="4"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="submit_button_container">
              <div
                className="submit_application_button"
                onClick={submitApplication}
              >
                Request Application
              </div>
            </div>
          </section>
        </form>
      </div>
    </div>
  );
};

export default OfflineApplicationRequest;
