import React, { useReducer } from "react";
import AlertContext from "./alertContext";
import alertReducer from "./alertReducer";
import { SET_ALERT, REMOVE_ALERT } from "../types";

const AlertState = (props) => {
  const initialState = {
    showAlert: false,
    message: "",
    buttonText: "",
    icon: "",
  };

  const [state, dispatch] = useReducer(alertReducer, initialState);

  // Set Alert
  const setAlert = (message, buttonText, icon) => {
    const alertData = {
      message: message,
      buttonText: buttonText,
      icon: icon,
    };

    dispatch({
      type: SET_ALERT,
      payload: alertData,
    });
  };

  const closeAlert = () => {
    dispatch({
      type: REMOVE_ALERT,
    });
  };
  return (
    <AlertContext.Provider
      value={{
        showAlert: state.showAlert,
        message: state.message,
        buttonText: state.buttonText,
        icon: state.icon,
        setAlert,
        closeAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertState;
