import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";

import "../css/donate.css";

const stripePromise = loadStripe(
  "pk_test_51Nv6bKLvRJxIiBtiFuUHn0ytN6onx6SWWn28XaUEfXSmopGnECvP5eipMzrVcte7cCCuFZzyCZ9rHDMAYHXkj9GM00kxpst8kT"
);

const Donate = () => {
  const [donatePage, setDonatePage] = useState("donate");
  const [clientSecret, setClientSecret] = useState("");
  const [paymentDetails, setPaymentDetails] = useState({
    Name: "",
    Phone: "",
    Email: "",
    Address: "",
    City: "",
    State: "",
    Zip: "",
    Reason: "",
    Detail: "",
    Status: "Payment Started",
    Additional_Details: "",
    Amount: 0.0,
    Surcharge: 0.0,
    Total: 0.0,
  });

  const [detailLabel, setDetailLabel] = useState("");

  useEffect(() => {
    if (paymentDetails.Reason === "Adoption")
      setDetailLabel("Application # (Found in the subject of approval email)");
    if (paymentDetails.Reason === "Donation")
      setDetailLabel("Reason for Donation");
    if (paymentDetails.Reason === "Other") setDetailLabel("Details of payment");
  }, [paymentDetails.Reason]);

  const handleInput = (e) => {
    if (e.target.name === "Amount") {
      if (e.target.value > 1.0) {
        console.log(true);
        setErrors({ ...errors, Amount: false });
      }
      const amt = parseFloat(e.target.value);
      const amt2 = amt.toFixed(2);
      const surCharge = parseFloat(amt * 0.032 + 0.3);
      const surCharge2 = parseFloat(surCharge).toFixed(2);
      const total = parseFloat(amt) + parseFloat(surCharge2);

      setPaymentDetails({
        ...paymentDetails,
        Amount: amt2,
        Surcharge: surCharge2,
        Total: total,
      });
    } else {
      if (e.target.name === "Name" && e.target.value.length > 0) {
        setErrors({ ...errors, Name: false });
      }
      if (e.target.name === "Phone" && e.target.value.length > 0) {
        setErrors({ ...errors, Phone: false });
      }
      if (e.target.name === "Email" && e.target.value.length > 0) {
        setErrors({ ...errors, Email: false });
      }
      if (e.target.name === "Address" && e.target.value.length > 0) {
        setErrors({ ...errors, Address: false });
      }
      if (e.target.name === "City" && e.target.value.length > 0) {
        setErrors({ ...errors, City: false });
      }
      if (e.target.name === "State" && e.target.value.length > 0) {
        setErrors({ ...errors, State: false });
      }
      if (e.target.name === "Zip" && e.target.value.length > 0) {
        setErrors({ ...errors, Zip: false });
      }
      setPaymentDetails({ ...paymentDetails, [e.target.name]: e.target.value });
    }
  };

  const [errors, setErrors] = useState({
    Name: false,
    Phone: false,
    Email: false,
    Amount: false,
  });

  const validateInputs = () => {
    let name = false;
    let phone = false;
    let email = false;
    let amount = false;
    let valid = true;

    if (paymentDetails.Name === "") {
      name = true;
      valid = false;
    }

    if (paymentDetails.Phone === "") {
      phone = true;
      valid = false;
    }
    if (paymentDetails.Email === "") {
      email = true;
      valid = false;
    }
    if (parseInt(paymentDetails.Amount) < 1.0) {
      amount = true;
      valid = false;
    }

    setErrors({
      Name: name,
      Phone: phone,
      Email: email,
      Amount: amount,
    });

    return valid;
  };

  const checkout = () => {
    const valided = validateInputs();
    if (valided) {
      fetch("/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(paymentDetails),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data.clientSecret);
          setClientSecret(data.clientSecret);
          window.scroll(0, 0);
          setDonatePage("charge");
        });
    } else {
      window.scroll(0, 0);
    }
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  switch (donatePage) {
    default:
    case "donate":
      return (
        <>
          <div className="donate_body_container">
            <div className="donate_container">
              <div className="epay_title_container">
                <h5 className="epay_sub_title_text">Online Donation Form</h5>
                <p className="epay_instruction_text">
                  Please fill out the below form to make an online payment to
                  Puppy Paws Rescue. Please note that the credit card processing
                  fees charged to the rescue will be added to the amount that
                  you are charging. (3.2% of Amount plus a .30 transaction fee).
                </p>
              </div>
              <div className="payment_details_form_container">
                <div className="w-form">
                  <form className="epay_payment_details_form">
                    <label htmlFor="name" className="epay_form_field">
                      Name
                      <span>
                        {errors.Name && (
                          <div className="form_error_message">
                            Full Name is Required
                          </div>
                        )}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="epay_form_Text_input"
                      maxLength="256"
                      name="Name"
                      placeholder=""
                      id="Name"
                      onChange={handleInput}
                    />
                    <label htmlFor="Phone" className="epay_form_field">
                      Phone{" "}
                      <span>
                        {errors.Phone && (
                          <div className="form_error_message">
                            A Phone number is Required
                          </div>
                        )}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="epay_form_Text_input"
                      maxLength="256"
                      name="Phone"
                      placeholder=""
                      id="Phone"
                      onChange={handleInput}
                    />
                    <label htmlFor="Email" className="epay_form_field">
                      Email Address{" "}
                      <span>
                        {errors.Email && (
                          <div className="form_error_message">
                            An Email Address is Required
                          </div>
                        )}
                      </span>
                    </label>
                    <input
                      type="email"
                      className="epay_form_Text_input"
                      maxLength="256"
                      name="Email"
                      placeholder=""
                      id="Email"
                      onChange={handleInput}
                    />
                    <label htmlFor="Address" className="epay_form_field">
                      Street Address
                      <span>
                        {errors.Address && (
                          <div className="form_error_message">
                            A Street Address is Required
                          </div>
                        )}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="epay_form_Text_input"
                      maxLength="256"
                      name="Address"
                      placeholder=""
                      id="Address"
                      onChange={handleInput}
                    />
                    <label htmlFor="City" className="epay_form_field">
                      City
                      <span>
                        {errors.City && (
                          <div className="form_error_message">
                            A City is Required
                          </div>
                        )}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="epay_form_Text_input"
                      maxLength="256"
                      name="City"
                      placeholder=""
                      id="City"
                      onChange={handleInput}
                    />
                    <label htmlFor="State" className="epay_form_field">
                      State
                      <span>
                        {errors.State && (
                          <div className="form_error_message">
                            A State is Required
                          </div>
                        )}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="epay_form_Text_input"
                      maxLength="256"
                      name="State"
                      placeholder=""
                      id="State"
                      onChange={handleInput}
                    />
                    <label htmlFor="Zip" className="epay_form_field">
                      Zip
                      <span>
                        {errors.Zip && (
                          <div className="form_error_message">
                            A Zip Code is Required
                          </div>
                        )}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="epay_form_Text_input"
                      maxLength="256"
                      name="Zip"
                      placeholder=""
                      id="Zip"
                      onChange={handleInput}
                    />
                    <label htmlFor="Reason" className="epay_form_field">
                      Reason For Donation
                    </label>
                    <select
                      id="Reason"
                      name="Reason"
                      className="epay_form_Select_input"
                      onChange={handleInput}
                    >
                      <option value="">Select one...</option>
                      <option value="Adoption">Adoption</option>
                      <option value="Donation">Donation </option>
                      <option value="Other">Other</option>
                    </select>
                    {paymentDetails.Reason !== "" ||
                    paymentDetails === undefined ? (
                      <>
                        {paymentDetails.Reason === "Adoption" ? (
                          <>
                            <label htmlFor="Detail" className="epay_form_field">
                              {detailLabel}
                            </label>
                            <input
                              type="text"
                              className="epay_form_Text_input"
                              maxLength="256"
                              name="Detail"
                              placeholder=""
                              id="Detail"
                              onChange={handleInput}
                            />
                          </>
                        ) : null}

                        <label
                          htmlFor="Additional_Details"
                          className="epay_form_field"
                        >
                          Notes
                        </label>
                        <textarea
                          placeholder="Enter any details pertaining to this donation"
                          maxLength="5000"
                          rows={8}
                          id="Additional_Details"
                          name="Additional_Details"
                          className="epay_form_TextArea_input"
                          onChange={handleInput}
                        ></textarea>
                        <label htmlFor="Amount" className="epay_form_field">
                          Amount
                          {errors.Amount && (
                            <div className="form_error_message">
                              Please enter a valid amount to charge.
                            </div>
                          )}
                        </label>
                        <input
                          type="text"
                          className="epay_form_Text_input"
                          maxLength="10"
                          name="Amount"
                          id="Amount"
                          required={true}
                          onChange={handleInput}
                          placeholder="Enter dollars and cents only (Example: 100.00)"
                        />
                      </>
                    ) : null}
                  </form>
                </div>
              </div>
              {paymentDetails.Reason !== "" || paymentDetails === undefined ? (
                <>
                  <div className="epay_amount_details">
                    <div className="epay_amount_title">
                      <h3 className="epay_amounts_title_text">
                        Summary of Charges
                      </h3>
                    </div>
                    <div className="epay_amount_table">
                      <div className="epay_amounts_left">
                        <h3 className="epay_amount_table_text">
                          {" "}
                          Payment Amount{" "}
                        </h3>
                        <h3 className="epay_amount_table_text">
                          Credit Card Processing Fee
                        </h3>
                        <h3 className="epay_amount_table_text">
                          Total Charge Amount
                        </h3>
                      </div>
                      <div className="epay_amounts_right">
                        <h3 className="epay_amount_table_text">
                          {isNaN(paymentDetails.Amount)
                            ? "$0.00"
                            : "$" + paymentDetails.Amount}
                        </h3>
                        <h3 className="epay_amount_table_text">
                          {isNaN(paymentDetails.Surcharge)
                            ? "$0.00"
                            : "$" + paymentDetails.Surcharge}
                        </h3>
                        <h3 className="epay_amount_table_text">
                          {isNaN(paymentDetails.Total)
                            ? "$0.00"
                            : "$" + paymentDetails.Total.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="checkout_button_container">
                    <div onClick={checkout} className="checkout_button">
                      Check Out
                    </div>
                  </div>
                  <div className="checkout_container"></div>
                </>
              ) : null}
            </div>
          </div>
        </>
      );
    case "charge":
      return (
        <>
          <div className="donate_body_container">
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                setDonatePage={setDonatePage}
                paymentDetails={paymentDetails}
                setPaymentDetails={setPaymentDetails}
              />
            </Elements>
          </div>
        </>
      );
    case "success":
      return (
        <>
          <div className="donate_body_container">
            <div className="donate_container">SUCCESS</div>
          </div>
        </>
      );
    case "error":
      return (
        <>
          <div className="donate_body_container">
            <div className="donate_container">ERROR</div>
          </div>
        </>
      );
  }
};

export default Donate;
