import { SET_ALERT, REMOVE_ALERT } from "../types";

const alertReducer = (state, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        showAlert: true,
        message: action.payload.message,
        buttonText: action.payload.buttonText,
        icon: action.payload.icon,
      };
    case REMOVE_ALERT:
      return {
        ...state,
        showAlert: false,
        message: "",
        buttonText: "",
      };
    default:
      return state;
  }
};

export default alertReducer;
