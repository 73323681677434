import React from "react";
import Button from "../components/Button";

import PuppyLineUp from "../media/PuppyLineUp2.png";
import PitPup from "../media/CutePitBull-Cropped.png";
// import PuppyLineUp from "../media/puppyLineUp.png";
// import PitPup from "../media/pitPuppy-removebg-preview.png";

const Home = () => {
  return (
    <>
      <section className="public_hero_section">
        <div className="public_hero_container">
          <div className="public_hero_left_column">
            <div className="public_hero_text_container">
              <h1 className="public_hero_headline">Unconditional Love</h1>
              <div className="public_hero_subtext">
                Don't shop the stores or breeders.{" "}
                <span className="public_hero_subtext_bold">
                  Rescue instead!
                </span>
              </div>
            </div>
            <div className="heroButtonContainer">
              <Button
                link={"dogs"}
                btnStyle={"dark"}
                buttonText={"BROWSE OUR AVAILABLE DOGS"}
              />
            </div>
          </div>
          <div className="public_hero_right_column">
            <div className="public_hero_image_container">
              <img
                src={PuppyLineUp}
                alt="puppies sitting side by side"
                className="public_hero_image"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="public_why_adopt">
        <div className="public_why_adopt_background">
          <div className="public_why_headline_container">
            <h1 className="public_why_headline">Reasons To Adopt</h1>
            <div className="public_why_subtitle"> source: ASPCA - Oct 2019</div>
          </div>
          <div className="public_why_text_container">
            <div className="public_why_text_container">
              <div className="public_why_list_item">
                <span className="text-span">
                  <strong className="public_why_list_headline">
                    1. You’re Saving More Than One Life
                  </strong>
                </span>{" "}
                - It goes without saying that when you adopt a rescue pet,
                you’re saving a life—but you’re actually saving more than one.
                By adopting, you’re helping make space for another animal in
                need and helping to give them the opportunity to become beloved
                pets.
              </div>
              <div className="public_why_list_item">
                <strong className="public_why_list_headline">
                  2. Unconditional Love! What Could Be Better?
                </strong>{" "}
                Many people worry about connecting with a rescued dog, but
                shelter dogs have so much love to give—and they won’t ever stop
                giving it to you once you let them into your heart!
              </div>
              <div className="public_why_list_item">
                <span className="public_why_list_headline">
                  <strong>
                    3. You’re Giving a Second Chance to a Deserving Animal
                  </strong>
                </span>{" "}
                - Beyond just helping an animal in need, you’re giving a rescue
                an opportunity to find their voice; to be themselves and get a
                second chance to become a dog beyond the walls of shelter or
                rescue. You truly give them the keys to start anew in a life
                where second chances can often be hard to come by.
              </div>
              <div className="public_why_list_item">
                <span className="public_why_list_headline">
                  4. You Get a Chance to Stay Active
                </span>{" "}
                - Maybe you’re trying to live a more active lifestyle, or maybe
                you’re just looking for a new adventure. Either way, a new
                four-legged friend gives you a reason to get outdoors more and
                stretch your legs!
              </div>
              <div className="public_why_list_item">
                <span className="public_why_list_headline">
                  5. You Have Someone New to Shop For
                </span>{" "}
                - It’s always fun to spoil your pets and bringing home a new
                furry family member gives you a reason to do just that. You can
                enjoy all the retail therapy you want while making sure your new
                rescue dog is living in the lap of luxury.
              </div>
              <div className="public_why_list_item">
                <span className="public_why_list_headline">
                  6. You’re Fighting Back Against Cruel Breeding
                </span>{" "}
                - Puppies purchased at pet stores almost always come from cruel
                breeding facilities where dogs are confined to small, filthy
                spaces and receive little to no veterinary care. By adopting
                from your local shelter or rescue, you are giving back to your
                community instead of helping cruel breeders profit.
              </div>
              <div className="public_why_list_item">
                <span className="public_why_list_headline">
                  7. Destress and Unwind with Someone Who Will Never Judge You
                </span>{" "}
                - Life is full of stresses, but your rescue dog is always there
                to listen. They won’t ever judge you or let you down. Taking
                some time to destress with your furry friends can help you
                unwind and keep you at peace.
              </div>
              <div className="public_why_list_item">
                <span className="public_why_list_headline">
                  8. Increase Your Social Interactions
                </span>{" "}
                - Getting out there with your pet can also help you make new
                human friends, too! You can befriend other pet parents, or even
                meet someone special when you’re making the rounds at your local
                dog park or dog-friendly café.
              </div>
              <div className="public_why_list_item">
                <span className="public_why_list_headline">
                  9. You’ll Have a Lifelong BFF{" "}
                </span>
                - What could be better than having a lifelong friend? In your
                time with your rescue dog, you’ll have a confidante, a pal and
                ultimately—a beloved family member. You’ll never feel lonely,
                and in return neither will your shelter dog.
              </div>
              <div className="public_why_list_item">
                <span className="public_why_list_headline">
                  10. Life Will Never Be Boring Again
                </span>{" "}
                - One thing that’s for certain, is that life with a rescue dog
                brings big changes—in the best way! Say goodbye to predictable
                nights and your boring routine and say hello to a new lease on
                life. Your new pet will keep life exciting, fresh and full of
                love.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="public_donate_section">
        <div className="public_donate_container">
          <div className="public_donate_columns">
            <div className="public_donate_left_column">
              <div className="public_donate_left_column_image_container">
                <img
                  src={PitPup}
                  alt="Cute Pit Puppy"
                  className="public_donate_image"
                />
              </div>
            </div>
            <div className="public_donate_right_column">
              <div className="public_donate_column_text_container">
                <h1 className="public_donate_headline">How can you help?</h1>
                <div className="public_donate_text">
                  There are many ways to help our rescue.
                </div>
                <div className="Donate_List_Text_Container">
                  <ol className="Donate_List">
                    <li className="Donate_List_Item">
                      Purchase items for the rescue from our Chewy Wish List,{" "}
                      <a
                        href="https://www.chewy.com/g/puppy-paws-rescue_b71615192"
                        className="donate_link"
                      >
                        Click Here
                      </a>
                      .
                    </li>
                    <li className="Donate_List_Item">
                      Apply to become a foster for the rescue{" "}
                      <a href="/foster" className="donate_link">
                        Click Here
                      </a>
                      .
                    </li>
                    <li className="Donate_List_Item">
                      Provide transportation for dogs coming into the rescue.
                      Contact the rescue for more information{" "}
                      <a href="/contact" className="donate_link">
                        Click Here
                      </a>
                      .
                    </li>
                    <li className="Donate_List_Item">
                      Follow us on our social media pages and share posts to
                      your friends and families (
                      <a
                        href="https://www.facebook.com/PuppyPawsRescue"
                        className="donate_link"
                      >
                        Facebook
                      </a>{" "}
                      <a
                        href="https://www.instagram.com/puppypawsrescue/"
                        className="donate_link"
                      >
                        Instagram
                      </a>
                      ) .
                    </li>
                    <li className="Donate_List_Item">
                      Make a financial donation to the rescue{" "}
                      <a href="/donate" className="donate_link">
                        Click Here
                      </a>
                      .
                    </li>
                  </ol>
                </div>
                <Button
                  link={"donate"}
                  btnStyle={"dark"}
                  buttonText={"DONATE"}
                  addStyle={"public_donate_button"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
