import React from "react";

const About = () => {
  return (
    <>
      <div className="about_body_container">
        <div className="about_card_container">
          <div className="about_title_container">
            <h1 className="about_title_text">About the Puppy Paw Rescue</h1>
          </div>
          <div className="about_subheading_text_container">
            <p className="about_subheading_text">
              Puppy Paws Rescue was created in March of 2013 in order to find
              loving homes for abandoned puppies and dogs. We are a non-profit
              (501c3) Also like us on facebook to get constant updates on
              available dogs, current adoptions and rescue news. Puppy Paws
              saves dogs from high kill shelters from the eastern portion of the
              United States. With the help of many foster families we typically
              have a large inventory of puppies and dogs available for adoption
              in the Maryland and northern Virginia area.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
