export const InitialApplicationState = {
  Acclimate: "",
  Adoption_Aware_Fee: "",
  Adults_Aware: "",
  Age_of_Children: "",
  Allergies: "",
  Alone_Time: "",
  Apartment: "",
  Application_Type: "Adoption",
  Application_Status: "New Application",
  Lead_Status: "New Application",
  Lead_Source: "Web Applications",
  Baby: "",
  Barks: "",
  Bills: "",
  Bladder_Issues: "",
  Chronic_Illness: "",
  City: "",
  CoApp_Full_Name: "",
  Condo: "",
  Convictions: "",
  Corrections: "",
  Costs: "",
  County: "",
  Divorce: "",
  Email: "",
  Employer1: "",
  Employer2: "",
  Encourage: "",
  Exercise: "",
  Expense: "",
  First_Name: "",
  Future_Dog2: "",
  Fence: "",
  Fence_Size: "",
  Foster_Length: "",
  Given_Away: "",
  Grooming: "",
  Hours1: "",
  Hours2: "",
  House: "",
  Housebreak: "",
  If_Allergies: "",
  If_Move: "",
  If_Die: "",
  Interest_Adult: "",
  Interest_Puppy: "",
  Interest_Mom: "",
  Interest_Litter: "",
  Interest_Set2Pups: "",
  Interest_NoPref: "",
  Interest_Male: "",
  Interest_Female: "",
  Interest_Either: "",
  Interest_Large: "",
  Interest_Medium: "",
  Interest_Small: "",
  Interest_SzNoPref: "",
  Interest_AddNeeds: "",
  Introductions: "",
  Killed_By_Vehicle: "",
  Kept_Where: "",
  Last_Name: "",
  Landlord_Name: "",
  Landlord_Phone: "",
  Lifetime_Commitment: "",
  Lived_Length_Home: "",
  Location_When_Home: "",
  Location_When_Not_Home: "",
  Location_When_Traveling: "",
  Location_When_Sleeping: "",
  Lost_Stolen: "",
  Medical_Care: "",
  Middle_Initial: "",
  Mobile: "",
  Mobile_Home: "",
  Moving: "",
  Move_Out_Of_State: "",
  Move_No_Pets: "",
  nd_Choice: "",
  Neighbors: "",
  None_Apply: "",
  Normal_Behavior: "",
  Nothing_Applies: "",
  Number_Household: "",
  Of_Age: "",
  Other_Adult_Names: "",
  Other_Checkbox: "",
  Other_Reasons: "",
  Other_Vet: "",
  Description: "",
  Outcome_Explain: "",
  Owner: { id: "35194048" },
  Owner_Id: "1045",
  Own_Rent: "",
  Partner_Allergies: "",
  Past_Addresses: "",
  Pet1_Age: "",
  Pet1_Breed: "",
  Pet1_Fixed: "",
  Pet1_How_long: "",
  Pet1_Kept: "",
  Pet1_Name: "",
  Pet1_Sex: "",
  Pet1_Vac: "",
  Pet1_WhereNow: "",
  Pet2_Age: "",
  Pet2_Breed: "",
  Pet2_Fixed: "",
  Pet2_How_long: "",
  Pet2_Kept: "",
  Pet2_Name: "",
  Pet2_Sex: "",
  Pet2_Vac: "",
  Pet2_WhereNow: "",
  Pet3_Age: "",
  Pet3_Breed: "",
  Pet3_Fixed: "",
  Pet3_How_long: "",
  Pet3_Kept: "",
  Pet3_Name: "",
  Pet3_Sex: "",
  Pet3_Vac: "",
  Pet3_WhereNow: "",
  Pet4_Age: "",
  Pet4_Breed: "",
  Pet4_Fixed: "",
  Pet4_How_long: "",
  Pet4_Kept: "",
  Pet4_Name: "",
  Pet4_Sex: "",
  Pet4_Vac: "",
  Pet4_WhereNow: "",
  Pet_Food: "",
  Pet_Incompatible: "",
  Pet_Not_As_Expected: "",
  Pets_Permitted: "",
  Phone: "",
  Policy_Acknowledged: "",
  Preperations: "",
  Primary_Caregiver: "",
  Reference_1: "",
  Reference_2: "",
  Reference_1_Phone: "",
  Reference_2_Phone: "",
  st_Choice: "",
  State: "",
  Street: "",
  Townhouse: "",
  Training: "",
  Turned_In: "",
  Un_Trainable: "",
  Vac_Explain: "",
  Vet_Name: "",
  Vet_Number: "",
  Vet_Perm: "",
  When_Moving: "",
  Will_Give_Preventatives: "",
  Work: "",
  Zip_Code: "",
  OS_Type: navigator.appVersion,
  TimeStamp: Date.now(),
};
